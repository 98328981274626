import { Button, Col, Modal, Row } from 'react-bootstrap';
import Icon from '../Icon';
import { useState } from 'react';

export default function SaveResumeModal({setShow,show, save, print }) {
  const [name, setName] = useState("My First Resume");
  const handleClose = () => setShow(false);
  return (
    <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="p-4">
            <div className="media media-rg media-middle media-circle text-primary bg-primary bg-opacity-20 mb-3">
              <Icon icon="spark-fill" className="icon" />
            </div>
            <h2 className="h1">Save Resume</h2>
            <p>
              You can Either choose the save your resume to edit later or print it without saving.
               {/* <span className="text-dark">23 Mar, 2023</span>. */}
            </p>
            <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
              <div className="card-body">
              <div className='col-6'>
        <label className="form-label">
              Save As
            </label>
        <input
        className="form-control"
        placeholder='Phone Number'
        onChange={(e) => {
          setName(e.target.value)
      }}
        defaultValue="My Resume"
      />
      </div>
              </div>
            </div>

            <Row className="gx-4 mt-4 gap-sm-10">
              <Col sm="6">
                <Button variant="outline-light w-100" onClick={() => {
                  setShow(false)
                  save(name)
                }}>
                  Save
                </Button>
              </Col>
              <Col sm="6">
                <Button className="btn btn-primary w-100" onClick={() => {
                  setShow(false)
                  print(name)
                }}>
                  Print without saving
                </Button>
              </Col>
              <div className='mt-2'>
              <Col sm="6">
                <Button className="btn btn-primary w-100" onClick={() => {
                  setShow(false)
                  save(name)
                  print(name)
                }}>
                  Save And Print
                </Button>
              </Col>
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
