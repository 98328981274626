import React, { useState, useEffect } from "react";
import SideNav from "../../../ui/SideNav";
import Footer from "../../../ui/Footer";
import { decodeToken } from "../../../../../apiCalls/userToken";
import { getUsersData } from "../../../../../apiCalls/apiFetch";
import Subscription from "./subscription";

const UserDashboard = () => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const { userId, role, profilePicture } = await decodeToken();
      if (userId && role === "User") {
        const data = await getUsersData(userId);
        if (data) setUserData({ ...data, profilePicture });
      }
    };
    fetch();
  }, []);

  return (
    <div className="nk-app-root" data-sidebar-collapse="lg">
      <div className="nk-main">
        <SideNav userData={userData} />
        <div className="nk-wrap">
          <div className="nk-content">
            <div className="container-xl">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <Subscription userData={userData} />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
