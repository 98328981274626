import { useContext } from 'react'
import { BuilderContext } from '../index'
import ActionMenu from './Editor/ActionMenu'
import EducationItem from './Editor/EducationItem'
import ToggleButton from './Editor/ToggleButton'
import { Remove } from './Editor/Icons/Remove'


const BasicDetailsResume = ({a}) =>{
  const ctx = useContext(BuilderContext)
  let socials = a.Socials
  let education = a?.Education

    let profile =a?.Profile;


      const handleSocialChange = (type, property, value) => {
   
        const item = socials.items.filter((item) => item.type === type)
        const targetIndex = socials.items.indexOf(item[0])
        if (property === 'url'){

          socials.items.splice(targetIndex, 1, { ...item[0], url: value })
          ctx.updateInfo(socials)
        }
        else if (property === 'enabled') {
          const newMembers = socials.items.map((c, i) => {
            if (i === targetIndex) {
              return  { ...c, enabled: value };
            } else {
  
              return c;
            }
          });
          socials = {...socials,items:newMembers}
          ctx.updateInfo({...socials,items:newMembers})
        }
      }
      const handleChange = (type, property, value) => {
        if(type==="Summary"){
      profile = { ...profile, display: value }
        ctx.updateInfo({ ...profile, display: value })
        }else if(type==="Education"){
          education = { ...education, display: value }
          ctx.updateInfo({ ...education, display: value })
        }
   
      }

      const newItem = {
        degree: 'Software Engineering',
        institution:'University of kansas',
        dateFrom: 'Mar 2017',
        dateTo:'Dec 2019'

      }
      const savechange = (index,changes) =>{

        const newMembers = education.items.map((c, i) => {
          if (i === index) {
     
            return changes;
          } else {

            return c;
          }
        });
        education = {...education,items:newMembers}
      ctx.updateInfo(education)
    }



  return (
    <div className="tab-pane fade show active">
    <form className="px-2">
      <div>
<div className='row gy-4 gx-4 form-separator'>

        <div className='col-6'>
        <label className="form-label">
              Full Name
            </label>
        <input
        placeholder='Full name'
        className="form-control"
        onChange={(e) => {
          profile = { ...profile, name: e.target.value }
   
          ctx.updateInfo(profile)
      }}
        defaultValue={profile?.name}
      />
      </div>
      <div className='col-6'>
        <label className="form-label">
              Profession
            </label>
        <input
        className="form-control"
        placeholder='Profession'
        onChange={(e) => {
          profile = { ...profile, profession: e.target.value }
          ctx.updateInfo({ ...profile, profession: e.target.value })
      }}
        defaultValue={profile?.profession}
      />
      </div>
            <div className='col-6'>
        <label className="form-label">
              Email
            </label>
        <input
        className="form-control"
        placeholder='Email'
        // onChange={(e) =>
        //   setProfile({ ...profile, email: e.target.value })
        // }
        
        onChange={(e) => {
          profile = { ...profile, email: e.target.value }
          ctx.updateInfo({ ...profile, email: e.target.value })
      }}
        defaultValue={profile?.email}
      />
      </div>
            <div className='col-6'>
        <label className="form-label">
              Ph. Number
            </label>
        <input
        className="form-control"
        placeholder='Phone Number'
        // onChange={(e) =>
        //   setProfile({ ...profile, phoneNumber: e.target.value })
        // }
        onChange={(e) => {
          profile = { ...profile, phoneNumber: e.target.value }
          ctx.updateInfo({ ...profile, phoneNumber: e.target.value })
      }}
        defaultValue={profile?.phoneNumber}
      />
      </div>
      
        <div className="col-12">
          <div className="form-group">
          <div className='flex justify-items'>
            <label className="form-label">
              Summary
            </label>
            
            <ToggleButton
                className=''
                name="Summary"
                handleChange={handleChange}
                defaultValue={true}
              />
              </div>

            <div className="form-control-wrap">
              <textarea
                cols={30}
                rows={4}
                className="form-control"
                defaultValue={profile?.about}
                onChange={(e) => {
                  profile = { ...profile, about: e.target.value }
                    ctx.updateInfo({ ...profile, about: e.target.value })
                }}
              />
            </div>
            <div className="form-note d-flex justify-content-end">
              <span>0/500 Characters</span>
            </div>
          </div>
        </div>

        </div>
        <div className='row gy-4 gx-4 form-separator'>
        <div className='col-12'>
        <div className='flex justify-items'>
        <div className='flex justify-next'>
        <h4 className='form-label'>
              Educational History
            </h4>
            <ToggleButton
                className='mgl-2'
                name="Education"
                handleChange={handleChange}
                defaultValue={true}
              />
              </div>
            <ActionMenu
      btnHide={true}
      className="mt--10px"

        handleAddClick={() =>{
          education = {
            ...education,
            items: [...education.items, newItem],
          }
          ctx.updateInfo(education)
             }     }
        handleRemoveClick={() =>{
          education = {
            ...education,
            items: education?.items?.filter(
              (item, index) => index < education.items.length - 1
            )  
          }
          ctx.updateInfo(education)
        }}
      />
      </div>
            {education?.items?.map((item, index) => (
              <div className='edu-container'>
              <div className='flex'>
                <div className='float-left'>
                        <Remove color='#d1d5db' handleClick={() =>{
          const a = education.items.slice(0, index).concat(education.items.slice(index + 1));
          education.items = a;
          item = a;
          console.log("a",a,index)
          console.log("b",item,index)
          ctx.updateInfo(education)
        }} />
        </div>
        </div>
        <EducationItem
        className="form-control"
          key={index}
          index={index}
          data={item}
          saveChange={savechange}
        />
        </div>

      ))}

      </div>
      </div>
<div className=' row gy-4 gx-4 form-separator'>
<h4 className='form-title'>
              Socials
            </h4>
      {socials &&
        socials?.items?.map((item, index) => (
          <div className='col-12' key={index}>
            <div className='flex justify-items'>
                   <label className="form-label">
            {item?.type}
            </label>             
             <ToggleButton
                className=''
                name={item?.type}
                handleChange={handleSocialChange}
                defaultValue={item?.enabled}
              />
              </div>
            <div className='flex flex-row gap-x-5'>
              <input
              className='form-control'
                placeholder={`${item?.type} url`}
                defaultValue={item.url}
                onChange={(e) => {
                  handleSocialChange(item.type, 'url', e.target.value)
                }}
              />


            </div>
          </div>
        ))}


        </div>

      </div>
    </form>
  </div>
  )
}


export default BasicDetailsResume
