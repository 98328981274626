import { useState, useContext } from "react";
import { BuilderContext } from "../index";
import EmploymentItem from "./Editor/EmploymentItem";
import ActionMenu from "./Editor/ActionMenu";

import { Remove } from "./Editor/Icons/Remove";
import { Add } from "./Editor/Icons/Add";
import ProjectItem from "./Editor/ProjectItem";
import OtherItem from "./Editor/OtherItem";

const OthersResume = ({a}) => {
  const ctx = useContext(BuilderContext);
  const [OtherInfo, setOtherInfo] = useState(a?.Other);
  let items=[]


  const [projects, setProjects] = useState(a?.Projects);

  const newItem = {
    name:"Section Name",
    display:true,
    position: "Software Engineer - Google",
    dateFrom: "Mar 2017",
    dateTo: "Dec 2019",
    location: "Atlanta, GA",
    details: ["Some cool stuff"],
  };

  const savechanges = (index, changes) => {
    const newMembers = OtherInfo?.items?.map((c, i) => {
      if (i === index) {
        return changes;
      } else {
        return c;
      }
    });
    setOtherInfo({ ...OtherInfo, items: newMembers });
    ctx.updateInfo({ ...OtherInfo, items: newMembers });
  };
  const saveProjectchanges = (index, changes) => {
    const newMembers = projects.items?.map((c, i) => {
      if (i === index) {
        return changes;
      } else {
        return c;
      }
    });
    setProjects({ ...projects, items: newMembers });
    ctx.updateInfo({ ...projects, items: newMembers });
  };

  return (
    <div className="tab-pane fade show active">
       
      <form className="px-4 py-4">
        <div className="row gy-4 gx-4 form-separator">
        {OtherInfo?.items ? 
          <div className="col-12">
            <div className="flex justify-items">
              <label className="form-label">Other Section</label>

              <ActionMenu
                btnHide={true}
                className="mt--10px"
                handleAddClick={() => {       
                  setOtherInfo({
                    ...OtherInfo,
                    items: [...OtherInfo?.items, newItem],
                  });
                  ctx.updateInfo({
                    ...OtherInfo,
                    items: [...OtherInfo?.items, newItem],
                  });
                }}
                handleRemoveClick={() => {
                  const a = OtherInfo?.items?.filter(
                    (item, index) => index < OtherInfo?.items?.length - 1
                  );
                  setOtherInfo({
                    ...OtherInfo,
                    items: OtherInfo?.items?.filter(
                      (item, index) => index < OtherInfo?.items?.length - 1
                    ),
                  });
                  ctx.updateInfo({
                    ...OtherInfo,
                    items: a,
                  });
                }}
              />
            </div>
            {OtherInfo?.items?.map((item, index) => (
              <OtherItem
                className="form-control"
                key={index}
                index={index}
                data={item}
                savechanges={savechanges}
              />
            ))}
          </div>

:<>
<div className="flex justify-items">
<label className="form-label">
  Add A Section
  </label>
<ActionMenu
                btnHide={true}
                className="mt--10px"
                handleAddClick={() => {
                  items.push(newItem)
                  setOtherInfo({
                    ...OtherInfo,
                    items: items,
                  });
                  ctx.updateInfo({
                    ...OtherInfo,
                    items: items,
                  });

                 
                }}
                handleRemoveClick={() => {
                  const a = OtherInfo?.items?.filter(
                    (item, index) => index < OtherInfo?.items?.length - 1
                  );
                  setOtherInfo({
                    ...OtherInfo,
                    items: OtherInfo?.items?.filter(
                      (item, index) => index < OtherInfo?.items?.length - 1
                    ),
                  });
                  ctx.updateInfo({
                    ...OtherInfo,
                    items: a,
                  });
                }}
              />
              </div>
</>}
        </div>

      </form>
    </div>
  );
};

export default OthersResume;
