import { Col, Row } from 'react-bootstrap';
import CustomCard from '../../../ui/CustomCard';
import CustomCardStyle4 from '../../../ui/CustomCard/CustomCardStyle4';
// import RecentDocumentTable from '../../../ui/RecentDocumentTable';
import SectionHeading from '../../../ui/SectionHeading';
import { Link } from 'react-router-dom';

export default function Stats({userData}) {
  return (
    <>
      <SectionHeading title={`Welcome ${userData?.first_name}!`} />
      <div className="nk-block">
      <Row className="g-gs">
          <Col sm="6" xxl="3">
            <CustomCard
              cardBgClass="bg-purple"
              title="Total Resumes"
              icon="paper"
              infoNumber={userData?.resumes}
              infoText="Resumes"
              generatedNumber={userData?.resumes}
              generatedText="/unlimited Resumes Remaining"
              btnText="See Resumes"
              btnUrl="/resume-templates"
              btnColorClass="link-purple"
            />
          </Col>
          <Col sm="6" xxl="3">
            <CustomCard
              cardBgClass="bg-blue"
              title="Total Cover Letters"
              infoNumber={0}
              icon="letter"
              infoText="Cover letters"
              generatedNumber={0}
              generatedText="/unlimited Cover Letters"
              btnText="See All"
              btnUrl="/resume-templates"
              btnColorClass="link-blue"
            />
          </Col>
        </Row>
        </div>
        {/* <div className='mt-4 nk-block'>
        <Row className="g-gs">
          <Col sm="6" xxl="3">
            <CustomCard
              cardBgClass="bg-purple"
              title="Total Tweets"
              icon="twitter"
              infoNumber={452}
              infoText="Tweets"
              generatedNumber={1548}
              generatedText="/2000 Tweets posted"
              btnText="See History"
              btnUrl="/history"
              btnColorClass="link-purple"
            />
          </Col>
          <Col sm="6" xxl="3">
            <CustomCard
              cardBgClass="bg-blue"
              title="Total Linkedin Posts"
              infoNumber={3}
              icon="linkedin"
              infoText="Linkedin Posts"
              generatedNumber={7}
              generatedText="/10 Linkedin posts Posted"
              btnText="See All"
              btnUrl="/document-drafts"
              btnColorClass="link-blue"
            />
          </Col>
        </Row>
      </div> */}
      <SectionHeading
        title="Future Funcationalities"
        btnText="Explore All"
        btnUrl="/"
      />
      <div className="nk-block">
        <Row className="g-gs">
          <Col sm="6" xxl="3">
            <Link to="create-campaign">
            <CustomCardStyle4
            popular={true}
              title="Help Connect more with recruiters"
              subTitle="Automatic Linkedin Posts and Messages"
              icon="bulb-fill"
              iconBgClass="text-primary bg-primary"
            />
            </Link>
          </Col>
          <Col sm="6" xxl="3">
          <Link to="create-campaign">
            <CustomCardStyle4
              title="Social Media Posts"
              subTitle="Creative and engaging social media post for personal your brand."
              icon="spark-fill"
              iconBgClass="text-blue bg-blue"
              newBadge={true}
            />
            </Link>
          </Col>
          <Col sm="6" xxl="3">
          <Link to="resume-templates">
            <CustomCardStyle4
              title="Cover letter Generator"
              subTitle="Generate Cover later for a Job opening in seconds."
              icon="youtube-fill"
              iconBgClass="text-red bg-red"
            />
            </Link>
          </Col>
          <Col sm="6" xxl="3">
          <Link to="resume-templates">
            <CustomCardStyle4
              title="Generate Free Resume"
              subTitle="Generate Resume For each Job You apply."
              icon="laptop"
              iconBgClass="text-purple bg-purple"
            />
            </Link>
          </Col>
        </Row>
      </div>
      {/* <SectionHeading
        title="Recent Documents"
        btnText="See All"
        btnUrl="/document-saved"
        iconRight="chevron-right"
      />
      <div className="nk-block">
        <div className="card">
          <RecentDocumentTable />
        </div>
      </div> */}
    </>
  );
}
