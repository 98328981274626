import {  useState } from 'react'
import { Hide } from './Icons/Hide'
import Show from './Icons/Show'
import { Add } from './Icons/Add'
import { Remove } from './Icons/Remove'
const OtherItem = ({ data, index,savechanges, className }) => {

  console.log("Data",data)
  const [isToggled, setIsToggled] = useState(true)
  let empData = data
  let details = data.details
  const handleChange = ( e) => {
    const targetName = e.target.name
    empData = {...empData, [targetName]:e.target.value}
    savechanges(index,empData)
  }

  return (
    <div className='flex pt-10px'>
      <div className='look-btn-emp'>
      {!isToggled ? (
        <Hide
        handleClick={() => {
            setIsToggled(true)
          }}
        />
      ) : (
        <Show
        handleClick={() => {
            setIsToggled(!isToggled)
          }}
          />
      )}
      </div>
      <div>

<div className='col-12'>

      <input
      className={className}
      placeholder='Section Name'
        isDisabled={isToggled}
        name='name'
        defaultValue={empData.name}
        onChange={(e) => handleChange(e)}
        />
       </div>
      {!isToggled && (
       <>
       <div className='row '> 
       <div className='col-12 gy-2'>
          <label className="form-label">
          Header
            </label>     
       <input
      className={className}
      placeholder='Position'
        isDisabled={isToggled}
        name='name'
        defaultValue={empData.position}
        onChange={(e) => handleChange(e)}
        />
        </div>
        <div className='col-6 gy-2'>
          <label className="form-label">
          Date From 
            </label>
          <input
            className={className}
            placeholder='Date From - To'
            name='dateFrom'
            defaultValue={empData?.dateFrom}
            onChange={(e) => handleChange(e)}
          />
           </div>
           <div className='col-6 gy-2'>
          <label className="form-label">
          Date  To
            </label>
          <input
            className={className}
            placeholder='Date From - To'
            name='dateTo'
            defaultValue={empData?.dateTo}
            onChange={(e) => handleChange(e)}
          />
           </div>
           </div>
        
          <div className='col-12 gy-2'>
          <label className="form-label">
          Location
            </label>
          <input
            className={className}
            placeholder='Position Description'
            name='location'
            defaultValue={empData.location}
            onChange={(e) => handleChange(e)}
          />
          </div>
          <div className='col-12 gy-2 last-form-member'>
            <div className='flex justify-items pt-10px ml-20px '>
          <label className="form-label">
          details
            </label>
            <div className='flex'>
        <Add color='#d1d5db'     
         handleClick={() =>{
          const a = [...details,"New details"]
          details = a
          empData = {...empData, details:a}
          savechanges(index,empData)
        }} />

        <Remove color='#d1d5db' handleClick={() =>{
          const a = details.filter((a,i) => i !== details.length - 1)
          details = a;
          console.log(a)
          empData = {...empData, details:a}
          savechanges(index,empData)
        }} />
        </div>
      </div>
            {details.map((item,resIndex)=>(
              <div className='my-1'>
              <input
                className={className}
                placeholder='details'
                name='details'
                defaultValue={item}
                onChange={(e) => {
                  const nextCounters = details.map((c, i) => {
                    if (i === resIndex) {
                      // Increment the clicked counter
                      return e.target.value;
                    } else {
                      // The rest haven't changed
                      return c;
                    }
                  });
                  console.log(nextCounters)
                  details = nextCounters
                  empData ={...empData, details:nextCounters}
                  savechanges(index,empData)
                }}
              />
              
</div>
            ))}
          </div>
        </>
    
      )}
      </div>
      </div>

  )
}

export default OtherItem
