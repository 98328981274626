import axios from "axios";
import { url } from "./fetch";
// axios.defaults.baseURL = "http://10.106.229.151:8800";
axios.defaults.baseURL = url;

// axios.defaults.baseURL = 'https://resumez-290dc5e4aca4.herokuapp.com/'
axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
console.log(axios.defaults.headers.common)



  export const getUsersData = async (id) => {
    try {
      const { data } = await axios.get(`/api/Users/getUser/`, {
        params: {
          id,
        },
        // param
      });
  
      
      return data;
    } catch (error) {
      return null;
    }
  };
  export const getUsersStats = async (id) => {
    try {
      const { data } = await axios.get(`/api/Users/getUserStats/`, {
        params: {
          id,
        },
        // param
      });
  
      
      return data;
    } catch (error) {
      return null;
    }
  };
  export const getTwitterPostMessage = async (prompt) => {
    try {
      console.log(prompt)

      const { data } = await axios.post(`/api/x/try`, prompt);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const getLinkedinPostMessage = async (prompt) => {
    try {
      const { data } = await axios.post(`/api/linkedin/try`, prompt);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const twitterSignin = async (userId) => {
    try {
      const { data } = await axios.get(`/api/x/auth/${userId}`,);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const linkedinSignin = async (userId) => {
    try {
      const { data } = await axios.get(`/api/linkedin/auth/${userId}`,);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const AIGenerateResume = async (description,userId) => {
    try {
      console.log("b",description)
      const { data } = await axios.post(`/api/resumes/AiGenerate`, {
          description, userId
      });

      return data;
    } catch (error) {
      return null;
    }
  };
  export const getAdminsData = async (id) => {
    try {
      const { data } = await axios.get("/api/admin/getAdmin/", {
        params: {
          id,
        },
      });
      return data;
    } catch (error) {
      return null;
    }
  };
  export const getJobStatus = async (id) => {
    try {
      const {data} = await axios.get(`api/x/job-status/${id}`);
      return data;

    } catch (error) {
      console.error('Error fetching job status:', error);
      return null;
    }
  };

  export const getReviewersData = async (id) => {
    try {
      const { data } = await axios.get("/api/reviewers/getReviewer/", {
        params: {
          id,
        },
      });
      return data;
    } catch (error) {
      return null;
    }
  };
  export const getCampaigns = async (data1) => {
    try {
      const url = `/api/campaigns/getCampaigns/?page=${data1?.page}&status=${data1?.status}&researchArea=${data1?.researchArea}&search=${data1?.search}&limit=${data1?.limit}`;
      
      const { data } = await axios.get(url);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const getPublishedPapers = async (data1) => {
    try {

      const url = `/api/papers/getPublishedPapers/?page=${data1?.page}&publicationYear=${data1?.publicationYear}&issueCategory=${data1?.issueCategory || null}&status=Published&researchArea=${data1?.researchArea}&search=${data1?.search}&searchUserName=${data1?.searchUserName}&limit=${data1?.limit}`;
      
      const { data } = await axios.get(url);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const getReviewers = async (data1) => {
    try {
      const url = `/api/reviewers/getReviewers/?page=${data1?.page}&researchArea=${data1?.researchArea}&search=${data1?.search}&limit=${data1?.limit}`;
      
      const { data } = await axios.get(url);

      return data;
    } catch (error) {
      return null;
    }
  };

  export const getReviewersReviewingPapers = async (arr) => {
    try {
      const { data } = await axios.post(`/api/papers/getReviewersReviewingPapers`, { paperIds: arr });
      return data;
    } catch (error) {
      return null;
    }
  };
  export const getusersCampaigns = async (a) => {
    try {
      const { data } = await axios.post(`/api/campaigns/getUsersCampaigns`,  a );
      return data;
    } catch (error) {
      return null;
    }
  };

  export const getusersResumes = async (userId) => {
    try {
      const { data } = await axios.post(`/api/Resumes/getUsersResumes/${userId}`);
      return data;
    } catch (error) {
      return null;
    }
  };
  
  export const getActiveCampaigns = async () => {

    try {
      const { data } = await axios.get(`/api/campaigns/getActiveCampaigns`);
      return data;
    } catch (error) {
      return null;
    }
  };


  export const getResume= async (id) => {
    try {
      const { data } = await axios.get(`/api/resumes/getResume`, {
        params: {
          id,
        },
        // param
      });
      return data;
    } catch (error) {
      return null;
    }
  };
