import React, { useContext, useState } from "react";

import BasicDetailsResume from "./components/BasicDetails";
import ProfessionalDetailsResume from "./components/ProfessionalDetails";
import { toast } from "react-toastify";
import { decodeToken } from "../../../../../apiCalls/userToken";
import { userEditResume } from "../../../../../apiCalls/apiPut.jsx";
import SaveResumeModal from "../../../ui/Modals/SaveResumeModal.jsx";
import { generatePdf, userCreateResume } from "../../../../../apiCalls/apiPost.jsx";
import { renderToString } from "react-dom/server";
import { Template1Send, Template2Send } from "./components/templates/templateHtml.jsx";
import OthersResume from "./components/CreateSection.jsx";
import { AIGenerateResume } from "../../../../../apiCalls/apiFetch.jsx";
import AIGenerateResumeModal from "../../../ui/Modals/AIGenerateResumeModal.jsx";
import { BuilderContext } from "./index";

export default function ResumeEditor({ a, template, userData }) {
  const ctx = useContext(BuilderContext);
  const [activeTab, setActiveTab] = useState(1);
  const [show, setShow] = useState(false);
  const [jobDescriptionModalshow, setJobDescriptionModalShow] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [generating, setGenerating] = useState(false);
  let skillsData = a?.KeySkills;
  let skills = a?.KeySkills?.text;
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const save = async (name) => {
    const { userId, role } = await decodeToken();
    if (userId && role === "User") {
      const elementsObject = a;

      elementsObject.user = userId;
      elementsObject.name = name;
      elementsObject.template = template;
      let res
      if(!a._id){
        res = await userCreateResume(elementsObject);
      }else{
        res = await userEditResume(elementsObject);
      }
      console.log("res,", res);
      if (res.status === "Success") {
        toast("Success Saving Resume", {
          render: "Success",
          autoClose: 3000,
          type: "success",
          isLoading: false,
        });
      } else {
        toast(res.data,{
          render: "Something went wrong",
          autoClose: 3000,
          type: "error",
          isLoading: false,
        });
      }
    } else {
      toast.error("Sign in");
    }
  };

  const generateResume = async (description) => {
    console.log("a",description)
    setGenerating(true)
            const res = await AIGenerateResume(description,userData._id);
            
            setGenerating(false)
            console.log("res,",res);
            if (res.status === "Success") {
              const skillsArray = res.data.split(",");
              console.log("Skillsarr",skillsArray)
              const newskills =  skills.concat(skillsArray);
              console.log("newSkills",newskills)
              skillsData =  { ...skillsData, text:newskills}
              console.log("skillsData",skillsData)
              ctx.updateInfo(skillsData)
              toast("Success Generating Pdf", {
                render: "Success",
                autoClose: 3000,
                type: "success",
                isLoading: false,
              });
            }else if(res.data === "Your Trial Ended"){
              toast(res.data,{
                render: res.data,
                autoClose: 3000,
                type: "error",
                isLoading: false,
              });
            
            } else {
              toast(res.data,{
                render: "Something went wrong",
                autoClose: 3000,
                type: "error",
                isLoading: false,
              });
              console.log("abcd",res.data)
            }
        };
        const download = async (name) => {
          setDownloading(true)
          const elementsObject = a;
      
          // elementsObject.template = ;
          elementsObject.name = name;
          let html;
          if(template === 1){
            html = renderToString(<Template1Send a={a}/>);
          }else if(template ===2){
            html = renderToString(<Template2Send a={a}/>);
          }else if(template ===3){
      
          }
        
                  const res = await generatePdf(html,template,name);
                  setDownloading(false)
                  console.log("res,",res);
                  if (res.status === "Success") {
                    toast("Success Downloading Pdf", {
                      render: "Success",
                      autoClose: 3000,
                      type: "success",
                      isLoading: false,
                    });
                  } else {
                    toast(res.data,{
                      render: "Something went wrong",
                      autoClose: 3000,
                      type: "error",
                      isLoading: false,
                    });
                    console.log(res.data)
                  }
              };

  return (
    <div className="nk-editor-base resume-editor-container">
      <SaveResumeModal save={save} print={download} show={show} setShow={setShow} />
      <AIGenerateResumeModal generateResume={generateResume} show={jobDescriptionModalshow} setShow={setJobDescriptionModalShow} />
      <div className="resume-editor-inner">
        <div className="resume-editor-nav">
          <div className={`has-fixed`}>
            <button
              className="btn btn-primary w-40 ml-2"
              onClick={(e) => {
                e.preventDefault();
                setShow(true);
              }}
            >
              {downloading? <>Downloading</>:<> Save</>}
            </button>
            <button
            className="btn btn-primary ml-2"
            onClick={(e) => {
              e.preventDefault();
              setJobDescriptionModalShow(true);
            }}
          >
            {generating? <>Generating</>:<>Generate with AI</>}
          </button>

            <ul className={`nav nav-tabs nav-sm nav-tabs-s1 px-3 `}>
              <li className="nav-item flex-navigation">
                <button
                  className={` ${
                    activeTab === 1 ? "nav-link active" : "nav-link "
                  }`}
                  type="button"
                  onClick={() => handleTabClick(1)}
                >
                  Basic
                </button>
              </li>
              <li className="nav-item flex-navigation">
                <button
                  className={` ${
                    activeTab === 2 ? "nav-link active" : "nav-link "
                  }`}
                  type="button"
                  onClick={() => handleTabClick(2)}
                >
                  Professional
                </button>
              </li>
              <li className="nav-item flex-navigation">
                <button
                  className={` ${
                    activeTab === 3 ? "nav-link active" : "nav-link "
                  }`}
                  type="button"
                  onClick={() => handleTabClick(3)}
                >
                  Other Sections
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content">
          {activeTab === 1 && (
            <div className="mt-20">   
              <BasicDetailsResume a={a} />
            </div>
          )}

          {activeTab === 2 && <ProfessionalDetailsResume a={a} />}
          {activeTab === 3 && <OthersResume a={a} />}
        </div>
      </div>
    </div>
  );
}
