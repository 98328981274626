import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updateUserData } from "../../../../../apiCalls/apiPut.jsx";
import { decodeToken } from "../../../../../apiCalls/userToken.jsx";
import { useEffect } from "react";

const ContactDetailsProfileSetting = ({ UserData }) => {
  // console.log(UserData)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (UserData) {
      setValue("address", UserData?.address);
      setValue("city", UserData?.city);
      setValue("country", UserData?.country);
    }
  }, [UserData, setValue]);

  const onSubmit = async (data) => {
    const { userId, role } = await decodeToken();
    if (userId && role === "User") {
      const id = toast.loading("uploading Profile Picture...");
      // console.log(userId, data)
      const res = await updateUserData(userId, data);
      // console.log("res,", res);
      if (res.status === "Success") {
        toast.update(id, {
          render: "Success",
          autoClose: 5000,
          type: "success",
          isLoading: false,
        });
      } else {
        toast.update(id, {
          render: "Something went wrong",
          autoClose: 5000,
          type: "error",
          isLoading: false,
        });
      }
    } else {
      toast.error("Sign in or You are not a User");
    }
  };

  return (
    <div className="card contact-card">
      <div className="card-body">
        <h4 className="card-title">Contact Details</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Address Line </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("address")}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="control-label">City</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("city", {
                    required: "city is required",
                  })}
                />
                {errors.city && (
                  <span className="text-danger mt-2 d-inline-block">
                    {errors.city?.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="control-label">Country</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("country", {
                    required: "Country is required",
                  })}
                />
                {errors.country && (
                  <span className="text-danger mt-2 d-inline-block">
                    {errors.country?.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6 basicDetailsSubmitBUtton">
              <div className="submit-section submit-btn-up ">
                <button type="submit" className="btn btn-primary submit-btn">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactDetailsProfileSetting;
