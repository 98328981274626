
import React, { useContext, useState } from 'react'
import { BuilderContext } from '../../index'
import './template1.css'
import { toast } from 'react-toastify';
import { generatePdf } from '../../../../../../../apiCalls/apiPost';
import { renderToString } from 'react-dom/server';

    
function Template1Send({a}) {

  // const ctx = useContext(BuilderContext)
  const education = a?.Education
  const skills = a?.KeySkills
  const profile = a?.Profile
  // const contact = a?.Contact')
  const projects = a?.Projects
  const socials= a?.socials
  const certifications = a?.Certifications
  const employment = a?.Employment
return (

    <div className="temp1" >
    <div classNamee="temp1-inner">

    <div className="temp1-container">
      <div className="temp1-header">
          <h1>{profile?.name}</h1>
          <p>{profile?.email}</p>
          <p>{profile?.phoneNumber}</p>
          <p><a href={socials?.linkedin}>{socials?.linkedin}</a></p>
      </div>

      {(education?.display && 
      <div className="temp1-education">
      <div className="temp1-section-title">Education</div>
      {education?.items.map((item, index) => (
        <div className='temp1-education-flex'>
          <div>
          {(item?.degree && item?.degree !== "" &&
        <p className="temp1-job-title">{item?.degree} </p>
          )}
        {(item?.institution && item?.institution !== "" &&
        <p className="temp1-job-title">{item?.institution} </p>
        )}
        </div>
        <div className='float-right'>
        {(item.dateFrom && item.dateTo && item?.dateFrom !== "" && item?.dateTo !== "" &&
        <p>{`${item.dateFrom} - ${item.dateTo}`}</p>
        )}
         {(item.gpa && item?.gpa !== "" &&
        <p>{`GPA: ${item.gpa}`}</p>
         )}
        </div>
        
        </div>
))}           

      </div>
      )}


      {/* <!-- Work Experience --> */}
      {(employment?.display && 
      <div className="temp1-work-experience">
          <div className="temp1-section-title">Work Experience</div>
          {employment?.items.map((item, index) => (
  <div className="temp1-blocks"   key={index}>
    {(item?.position && 
    <span className="temp1-job-title">{item.position}</span>
    )}
    {(item?.dateFrom && item?.dateTo && 
    <span className="temp1-job-duration">{`${item.dateFrom} - ${item.dateTo}`}</span>
    )}

      <div>
        <ul>
       
        {item?.responsibilities?.map((item, index)=>(
<li key={index}> {item} </li>
       ) )}
      
        </ul>
        </div>
    </div>
      ))}
      </div>
      )}
       {(projects?.display && 
      <div className="temp1-work-experience">
          <div className="temp1-section-title">Projects</div>
          {projects?.items.map((item, index) => (
  <div className="temp1-blocks"   key={index}>
    <span className="temp1-job-title">{item.name}</span>
    {(item?.dateFrom && 
    <span className="temp1-job-duration">{`${item?.dateFrom} - ${item?.dateTo}`}</span>
  )}
      <div>
        <ul>
       
        {item?.description?.map((item, index)=>(
<li key={index}> {item} </li>
       ) )}
      
        </ul>
        </div>
    </div>
      ))}
      </div>
       )}
                {(certifications?.display && 
      <div className="temp1-work-experience">
          <div className="temp1-section-title">Certifications</div>
          <ul className="temp1-skills-container">
{certifications?.items?.map((item, index)=>(
<li className="temp1-skills-item" key={index}> {item} </li>
       ) )}
</ul>
      </div>
       )}

{(skills?.display && 
      <div className="temp1-work-experience">
          <div className="temp1-section-title">Skills</div>

<ul className="temp1-skills-container">
{skills?.text?.map((item, index)=>(
<li className="temp1-skills-item" key={index}> {item} </li>
       ) )}
</ul>

</div>
       )}


  </div>
  </div>
</div>

)
} 
function Template1({a}) {
    const [generating, setGenerating] = useState(false);
  const ctx = useContext(BuilderContext)
  const education = a?.Education
  const skills = a?.KeySkills
  const profile = a?.Profile
  const others = a?.Other
  const projects = a?.Projects
  const socials= a?.socials
  const certifications = a?.Certifications
  const employment = a?.Employment

  const downloadPdf = async () => {
    
    setGenerating(true)
    const html = renderToString(<Template1Send a={a}/>);
  
            const res = await generatePdf(html,1);
            setGenerating(false)
    
            if (res.status === "Success") {
              toast("Success generating pdf", {
                render: "Success",
                autoClose: 3000,
                type: "success",
                isLoading: false,
              });
            } else {
              toast({
                render: "Something went wrong",
                autoClose: 3000,
                type: "error",
                isLoading: false,
              });
              
            }
        };
  return (
    <BuilderContext.Provider value={ctx}>
    <div className="temp1" >
      <div className="temp1-inner">

      <div className="temp1-container">
        <div className="temp1-header">
            <h1>{profile?.name}</h1>
            <p>{profile?.email}</p>
            <p>{profile?.phoneNumber}</p>
            <p><a href={socials?.linkedin}>{socials?.linkedin}</a></p>
        </div>
        {profile?.display && profile?.about && profile.about !=="" &&
              <div className="temp1-education">
              <div className="temp1-section-title"><h3>Summary</h3></div>

                <div className='temp1-education-flex'>
                  <div>
 
                <p className="temp1-summary">{profile.about} </p>
           
                </div>
                
                </div>
       
      
              </div>
              }
        {(education?.display && 
        <div className="temp1-education">
        <div className="temp1-section-title"><h3>Education</h3></div>
        {education?.items.map((item, index) => (
          <div className='temp1-education-flex'>
            <div>
            {(item?.degree && item?.degree !== "" &&
          <p className="temp1-education-text">{item?.degree} </p>
            )}
          {(item?.institution && item?.institution !== "" &&
          <p className="temp1-education-text">{item?.institution} </p>
          )}
          </div>
          <div className='float-right'>
          {(item.dateFrom && item.dateTo && item?.dateFrom !== "" && item?.dateTo !== "" &&
          <p>{`${item.dateFrom} - ${item.dateTo}`}</p>
          )}
           {(item.gpa && item?.gpa !== "" &&
          <p>{`GPA: ${item.gpa}`}</p>
           )}
          </div>
          
          </div>
  ))}           

        </div>
        )}


        {/* <!-- Work Experience --> */}
        {(employment?.display && 
        <div className="temp1-work-experience">
            <div className="temp1-section-title"><h3>Work Experience</h3></div>
            {employment?.items.map((item, index) => (
    <div className="temp1-blocks"   key={index}>
      {(item?.position && 
      <span className="temp1-job-title">{item.position}</span>
      )}
      {(item?.dateFrom && item?.dateTo && 
      <span className="temp1-job-duration">{`${item.dateFrom} - ${item.dateTo}`}</span>
      )}

        <div>
          <ul>
         
          {item?.responsibilities?.map((item, index)=>(
<li key={index}> {item} </li>
         ) )}
        
          </ul>
          </div>
      </div>
        ))}
        </div>
        )}
         {(projects?.display && 
        <div className="temp1-work-experience">
            <div className="temp1-section-title"><h3>Projects</h3></div>
            {projects?.items.map((item, index) => (
    <div className="temp1-blocks"   key={index}>
      <span className="temp1-job-title">{item.title}</span>
      {(item?.dateFrom && 
      <span className="temp1-job-duration">{`${item?.dateFrom} - ${item?.dateTo}`}</span>
    )}
        <div>
          <ul>
         
          {item?.description?.map((item, index)=>(
<li key={index}> {item} </li>
         ) )}
        
          </ul>
          </div>
      </div>
        ))}
        </div>
         )}
                  {(certifications?.display && 
        <div className="temp1-work-experience">
            <div className="temp1-section-title"><h3>Certifications</h3></div>
            <ul className="temp1-skills-container">
  {certifications?.items?.map((item, index)=>(
<li className="temp1-skills-item" key={index}> {item} </li>
         ) )}
</ul>
        </div>
         )}

{others?.display && (
            <>
                    <div className="temp1-work-experience">
            <div className="temp1-section-title"><h3>Work Experience</h3></div>
            {employment?.items.map((item, index) => (
    <div className="temp1-blocks"   key={index}>
      {(item?.position && 
      <span className="temp1-job-title">{item.position}</span>
      )}
      {(item?.dateFrom && item?.dateTo && 
      <span className="temp1-job-duration">{`${item.dateFrom} - ${item.dateTo}`}</span>
      )}

        <div>
          <ul>
         
          {item?.responsibilities?.map((item, index)=>(
<li key={index}> {item} </li>
         ) )}
        
          </ul>
          </div>
      </div>
        ))}
        </div>
            {others?.items?.map((item, index) => (
              <div className="temp1-work-experience" key={index}>
                <div className="temp1-section-title"><h3>{item?.name}</h3></div>
             
                    <div className="temp1-blocks">
                    {(item?.position && 
      <span className="temp1-job-title">{item.position}</span>
      )}
                       {(item?.dateFrom && item?.dateFrom !=='' && item?.dateTo !=='' && 
      <span className="temp1-job-duration">{`${item?.dateFrom} - ${item?.dateTo}`}</span>
    )}
                          <div>
          <ul>
         
          {item?.details?.map((i, index)=>(
<li key={index}> {i} </li>
         ) )}
        
          </ul>
          </div>
                    </div>
             
           
              </div>
            ))}
            </>
          )}

{(skills?.display && 
        <div className="temp1-work-experience">
            <div className="temp1-section-title"><h3>Skills</h3></div>

<div className="temp1-skills-container">
  {skills?.text?.map((item, index)=>(
<div className="temp1-skills-item" key={index}><p> {item} </p></div>
         ) )}
</div>

</div>
         )}
 

    </div>
    </div>
</div>
<button onClick={()=> downloadPdf()} className='btn btn-primary'>
{generating? <>Generating ...</>: <> Download pdf</>}
</button>
</BuilderContext.Provider>
  )
}

export default Template1