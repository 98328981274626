import { Link } from "react-router-dom";
import CancelSubscriptionModal from '../../../ui/Modals/CancelSubscriptionModal.jsx'
import ChangePlanModal from '../../../ui/Modals/ChangePlanModal.jsx'

export default function Subscription({ userData }) {
  return (
    <div className="tab-pane fade show active">
      <div className="d-flex flex-wrap align-items-center justify-content-between border-bottom border-light mt-5 mb-4 pb-1">
        <h5 className="mb-0">Your Subscription</h5>
        <ul className="d-flex gap gx-4">
          <li>
            <CancelSubscriptionModal userData={userData} btnVariant="link link-danger fw-normal p-0" />
          </li>
          <li>
            <ChangePlanModal btnVariant="link link-primary fw-normal p-0" />
          </li>
        </ul>
      </div>
      {alert && (
        <div className="alert alert-warning alert-dismissible fade show mb-4 rounded-6">
          <p className="small mb-0">
            Save big up to 75% on your upgrade to our{" "}
            <strong>
              <Link className="alert-link" href="/pricing-plans">
                Enterprise plan
              </Link>
            </strong>{" "}
            and enjoy premium features at a fraction of the cost!
          </p>
          <div className="d-inline-flex position-absolute end-0 top-50 translate-middle-y me-2">
            <button
              type="button"
              className="btn btn-xs btn-icon btn-warning rounded-pill"
              // onClick={() => setAlert(false)}
            >
              <em className="icon ni ni-cross" />
            </button>
          </div>
        </div>
      )}

      <div className="row g-gs">
        <div className="col-xl-3 col-sm-6">
          <div className="card shadow-none">
            <div className="card-body">
              <div className="text-light mb-2">Plan</div>
              <h3 className="fw-normal">Professional Plan</h3>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card shadow-none">
            <div className="card-body">
              <div className="text-light mb-2">Recurring Payment</div>
              <h3 className="fw-normal">$23/Month</h3>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card shadow-none">
            <div className="card-body">
              <div className="text-light mb-2">Next Due Date</div>
              <h3 className="fw-normal">Mar 15, 2023</h3>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card shadow-none">
            <div className="card-body">
              <div className="text-light mb-2">Payment Method</div>
              <div className="d-flex align-items-center">
                <img src="" alt="" className="icon" />
                <image
                  className="icon"
                  width={24}
                  height={24}
                  src="/images/icons/paypal.png"
                />
                <h3 className="fw-normal ms-2">PayPal</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 mb-4 pb-2">
        <h5>Billing History</h5>
      </div>
      <div className="card">
        <table className="table table-middle mb-0">
          <thead className="table-light">
            <tr>
              <th className="tb-col">
                <div className="fs-13px text-base">Subscription</div>
              </th>
              <th className="tb-col tb-col-md">
                <div className="fs-13px text-base">Payment Date</div>
              </th>
              <th className="tb-col tb-col-sm">
                <div className="fs-13px text-base">Total</div>
              </th>
              <th className="tb-col tb-col-sm">
                <div className="fs-13px text-base">Status</div>
              </th>
              <th className="tb-col" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tb-col">
                <div className="caption-text">
                  Starter - 12 Months{" "}
                  <div className="d-sm-none dot bg-success" />
                </div>
              </td>
              <td className="tb-col tb-col-md">
                <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                  <span>Feb 15,2023 </span> <span>02:31 PM</span>
                </div>
              </td>
              <td className="tb-col tb-col-sm">
                <div className="fs-6 text-light">$23.00</div>
              </td>
              <td className="tb-col tb-col-sm">
                <div className="badge text-bg-success-soft rounded-pill px-2 py-1 fs-6 lh-sm">
                  Paid
                </div>
              </td>
              <td className="tb-col tb-col-end">
                <a href="/" className="link">
                  Get Invoice
                </a>
              </td>
            </tr>
            <tr>
              <td className="tb-col">
                <div className="caption-text">
                  Starter - 12 Months{" "}
                  <div className="d-sm-none dot bg-warning" />
                </div>
              </td>
              <td className="tb-col tb-col-md">
                <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                  <span>Feb 15,2023 </span> <span>02:31 PM</span>
                </div>
              </td>
              <td className="tb-col tb-col-sm">
                <div className="fs-6 text-light">$23.00</div>
              </td>
              <td className="tb-col tb-col-sm">
                <div className="badge text-bg-danger-soft rounded-pill px-2 py-1 fs-6 lh-sm">
                  Faild
                </div>
              </td>
              <td className="tb-col tb-col-end">
                <a href="/" className="link">
                  Get Invoice
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
