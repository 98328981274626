import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { decodeToken } from "../../../../../apiCalls/userToken";
import Icon from "../../../ui/Icon";
import { userCreateCampaign } from "../../../../../apiCalls/apiPost";
import { getusersCampaigns, linkedinSignin, twitterSignin } from "../../../../../apiCalls/apiFetch";

export default function DocumentEditorGeneratePage({userData}) {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [promptError, setPromptError] = useState(null);
  const [trialError, setTrialError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  function WordCount(str) {
    return str.split(" ").length;
  }
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const onSubmit = async (data) => {
    setTrialError(null)
    const schedule = Number(data.schedule)
    setPromptError(null);
    const campaigns = await getusersCampaigns({
      userId: userData?._id,
      type: "Twitter",
    });
    let number = 0
    // = campaigns.map((c,i)=>{

    // })
    for(let i =0 ; i<campaigns.length ; i++){
      console.log("A",campaigns[i].schedule, typeof(campaigns[i].schedule))
      number += Number(campaigns[i].schedule);
    }
    console.log("Number:",number,userData.plan,schedule)
    if((userData.plan === "Free" && (number+ schedule) <= 1 ) || ((userData.plan === "basic_y" || userData.plan === "basic_m") && (number+ schedule) <= 3 ) || ((userData.plan === "golden_y" || userData.plan === "golden_m") && (number+ schedule) <= 6 )){
    const PromptWordCOunt = WordCount(data.prompt);
    if (PromptWordCOunt <= 500) {
      const { userId, role } = await decodeToken();
      if (userId && role === "User") {
        const id = toast.loading("Wating for Response...");
        data = { ...data, user: userId, status: "Active" };
        const res = await userCreateCampaign(data);
        // const res = {status : "error"}
        if (res.status === "Success") {
          toast.update(id, {
            render: "Success",
            autoClose: 3000,
            type: "success",
            isLoading: false,
          });
          navigate(`/campaigns/${data.type}`);
        } else {
          toast.update(id, {
            render: "Try again later...",
            autoClose: 3000,
            type: "error",
            isLoading: false,
          });
        }
      } else {
        console.log("Not Authorized User!!!");
      }
    } else {
      setPromptError(
        `Word Count exceeds 500, the current word count is ${PromptWordCOunt}`
      );
    }
  }else{
    let error
  if (userData.plan === "golden_y" || userData.plan === "golden_m"  ){
    error = `On A Golden Subscription Maximum of 6 Posts per day is allowed, you currently have ${(number)} Posts per day.`
    setTrialError(error)
  
        toast.error(error,{
            autoClose: 3000,
            isLoading: false,
          });
  }else if(userData.plan === "basic_y" || userData.plan === "basic_m"  ){
    error = `On A Basic Subscription Maximum of 3 Posts per day is allowed, you currently have ${number} Posts per day.`
    setTrialError(error)
  
        toast.error(error,{
            autoClose: 3000,
            isLoading: false,
          });
  }else if(userData.plan === "Free" ){
    error = `On A free subscription maximum of 1 post per day is allowed, you currently have ${number} Posts per day.`
    setTrialError(error)
  
        toast.error(error,{
            autoClose: 3000,
            isLoading: false,
          });
  }
  }
  };
  const onLinkedinSubmit = async (data) => {
    setTrialError(null)
    const schedule = Number(data.schedule)
    setPromptError(null);
    const campaigns = await getusersCampaigns({
      userId: userData?._id,
      type: "Linkedin",
    });
    let number = 0
    // = campaigns.map((c,i)=>{

    // })
    for(let i =0 ; i<campaigns.length ; i++){
      console.log("A",campaigns[i].schedule, typeof(campaigns[i].schedule))
      number += Number(campaigns[i].schedule);
    }
    console.log("Number:",number,userData.plan,  schedule)
    if((userData.plan === "Free" && ((number+ schedule) <= 0 )) || ((userData.plan === "basic_y" || userData.plan === "basic_m") && ((number+ schedule) <= 3 )) || ((userData.plan === "golden_y" || userData.plan === "golden_m") && ((number+ schedule) <= 6 ))){
      // if((userData.plan === "Free" &&  schedule === 1 ) || ((userData.plan === "basic_y" || userData.plan === "basic_m") &&  schedule === 1 ) || ((userData.plan === "golden_y" || userData.plan === "golden_m") &&  schedule === 1 )){

      // }
    const PromptWordCOunt = WordCount(data.prompt);
    if (PromptWordCOunt <= 500) {
      const { userId, role } = await decodeToken();
      if (userId && role === "User") {
        const id = toast.loading("Waiting for Response...");
        data = { ...data, user: userId, status: "Active" };
        const res = await userCreateCampaign(data);
        // const res = {status : "error"}
        if (res.status === "Success") {
          toast.update(id, {
            render: "Success",
            autoClose: 3000,
            type: "success",
            isLoading: false,
          });
          navigate(`/campaigns/${data.type}`);
        } else {
          toast.update(id, {
            render: "Try again later...",
            autoClose: 3000,
            type: "error",
            isLoading: false,
          });
        }
      } else {
        console.log("Not Authorized User!!!");
      }
    } else {
      setPromptError(
        `Word Count exceeds 500, the current word count is ${PromptWordCOunt}`
      );
    }
  }else{
    let error
  if (userData.plan === "golden_y" || userData.plan === "golden_m"  ){
    if(number === 6){
      error = `On A Golden Subscription Maximum of 6 Posts per day is allowed, you currently have ${number} Posts per day. You have reached the maximum post per day. You can either delete one of your active campaigns or talk to us for a custom subscription.`
      setTrialError(error)
    
          toast.error(error,{
              autoClose: 3000,
              isLoading: false,
            });
    }else{
    error = `On A Golden Subscription Maximum of 6 Posts per day is allowed, you currently have ${number} Posts per day. The maximum You canschedule for now is ${(1-number)}`
    setTrialError(error)
  
        toast.error(error,{
            autoClose: 3000,
            isLoading: false,
          });
        }
  }else if(userData.plan === "basic_y" || userData.plan === "basic_m"  ){
    error = `On A Basic Subscription Maximum of 3 Posts per day is allowed, you currently have ${number} Posts per day. The maximum You canschedule for now is ${(3-number)}`
    setTrialError(error)
  
        toast.error(error,{
            autoClose: 3000,
            isLoading: false,
          });
  }else if(userData.plan === "Free" ){
    error = `On A free subscription maximum of 1 post per day is allowed, you currently have ${number} Posts per day. The maximum You can schedule for now is ${(6-number)}. You can either delete your active campaign or subscribe to Our Basic or Golden Subscription.`
    setTrialError(error)
  
        toast.error(error,{
            autoClose: 3000,
            isLoading: false,
          });
  }
  }
  };

  const connectTwitter = async () =>{
    const id = toast.loading("Waiting for Response...");
    const res = await twitterSignin(userData._id);
    // const res = {status : "error"}
    if (res.status === "Success") {
      toast.update(id, {
        render: "Success",
        autoClose: 3000,
        type: "success",
        isLoading: false,
      });
      window.open(res.url, "_blank", "noreferrer");
  } else {
    toast.update(id, {
      render: "Try again later...",
      autoClose: 3000,
      type: "error",
      isLoading: false,
    });
  } 
}
const connectLinkedin = async () =>{
  const id = toast.loading("Waiting for Response...");
  const res = await linkedinSignin(userData._id);
  // const res = {status : "error"}
  if (res.status === "Success") {
    toast.update(id, {
      render: "Success",
      autoClose: 3000,
      type: "success",
      isLoading: false,
    });
    window.open(res.url, "_blank", "noreferrer");
} else {
  toast.update(id, {
    render: "Try again later...",
    autoClose: 3000,
    type: "error",
    isLoading: false,
  });
} 
}

  return (
    <div className=" nk-editor-body">
      <div className="">
        <ul className="nav nav-tabs nav-sm nav-tabs-s1 px-3">
          <li className="nav-item flex-navigation">
            <Icon className="icon" icon="twitter" />
            <button
              className={` ${
                activeTab === 1 ? "nav-link active" : "nav-link "
              }`}
              type="button"
              onClick={() => handleTabClick(1)}
            >
              Twitter Campaign
            </button>
          </li>
          <li className="nav-item flex-navigation">
            <Icon className="icon" icon="linkedin" />
            <button
              className={` ${
                activeTab === 2 ? "nav-link active" : "nav-link "
              }`}
              type="button"
              onClick={() => handleTabClick(2)}
            >
              Linkedin Campaign
            </button>
          </li>
        </ul>
        <div className="tab-content">
          {activeTab === 1 && (
            <>
              <div className="tab-pane fade show active">
                <form onSubmit={handleSubmit(onSubmit)} className="px-4 py-4">
                  <div className="row gy-4 gx-4">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="PrimaryKeywords" className="form-label">
                          Title
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            {...register("title", {
                              required: "Title is required",
                            })}
                            className="form-control"
                            placeholder="Name of Your Campaign"
                          />
                          {errors.title && (
                            <span className="text-danger mt-2 d-inline-block">
                              {errors?.title?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">
                          Select Campaign Type
                        </label>
                        <div className="form-control-wrap">
                          <select
                            className="form-select"
                            defaultValue="Twitter"
                            {...register("type", {
                              required: "Type Required",
                            })}
                          >
                            <option >Twitter</option>
                            {/* <option>Linkedin</option> */}
                          </select>
                          {errors.type && (
                            <span className="text-danger mt-2 d-inline-block">
                              {errors.type?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">your prompt?</label>
                        <div className="form-control-wrap">
                          <textarea
                            cols={30}
                            rows={4}
                            className="form-control"
                            defaultValue={
                              "Write an interesting post about AI in the gaming industry"
                            }
                            {...register("prompt", {
                              required: "prompt is required",
                            })}
                          />
                          {(errors.prompt || promptError) && (
                            <span className="text-danger mt-2 d-inline-block">
                              {errors?.prompt?.message || promptError}
                            </span>
                          )}
                        </div>
                        <div className="form-note d-flex justify-content-end">
                          <span>0/500 Characters</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="PrimaryKeywords" className="form-label">
                          Schedule
                  
                          <span>/day</span>
                   </label>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            {...register("schedule", {
                              required: "Schedule is required",
                              validate: value => {
                                if (value > 6) return 'Max of 6 posts a day';
                                if (value < 1) return 'Min of 1 post a day';
                                return true;
                              }
                              // validate: value => value <= 6 || 'Min of 1 post a day'
                              
                            })}
                            className="form-control"
                            placeholder="6"
                          />
                          {(errors.schedule || trialError) && (
                            <span className="text-danger mt-2 d-inline-block">
                              {errors?.schedule?.message || trialError}
                            </span>
                          )}
                        </div>
                  
                      </div>
                    </div>

                    <div className="col-6">
                      <button className="btn btn-primary w-100" type="button" onClick={()=> connectTwitter()}>Connect My Twitter Account</button>
                    </div>

                    <div className="col-6">
                      <button className="btn btn-primary w-100">Create</button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
                    {activeTab === 2 && (
            <>
              <div className="tab-pane fade show active">
                <form onSubmit={handleSubmit(onLinkedinSubmit)} className="px-4 py-4">
                  <div className="row gy-4 gx-4">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="PrimaryKeywords" className="form-label">
                          Title
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            {...register("title", {
                              required: "Title is required",
                            })}
                            className="form-control"
                            placeholder="Name of Your Campaign"
                          
                          />
                          {errors.title && (
                            <span className="text-danger mt-2 d-inline-block">
                              {errors?.title?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">
                          Select Campaign Type
                        </label>
                        <div className="form-control-wrap">
                          <select
                            className="form-select"
                            defaultValue="Linkedin"
                            {...register("type", {
                              required: "Type Required",
                            })}
                          >
                            {/* <option selected>Twitter</option> */}
                            <option >Linkedin</option>
                          </select>
                          {errors.type && (
                            <span className="text-danger mt-2 d-inline-block">
                              {errors.type?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">Your Prompt</label>
                        <div className="form-control-wrap">
                          <textarea
                            cols={30}
                            rows={4}
                            className="form-control"
                            defaultValue={
                              "Write an interesting post about AI in the gaming industry"
                            }
                            {...register("prompt", {
                              required: "prompt is required",
                            })}
                          />
                          {(errors.prompt || promptError) && (
                            <span className="text-danger mt-2 d-inline-block">
                              {errors?.prompt?.message || promptError}
                            </span>
                          )}
                        </div>
                        <div className="form-note d-flex justify-content-end">
                          <span>0/500 Characters</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="PrimaryKeywords" className="form-label">
                          Schedule
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            {...register("schedule", {
                              required: "Schedule is required",
                              validate: value => {
                                if (value > 6) return 'Max of 6 posts a day';
                                if (value < 1) return 'Min of 1 post a day';
                                return true;
                              }
                            })}
                            className="form-control"
                            defaultValue={1}
                          />
                          {errors.schedule && (
                            <span className="text-danger mt-2 d-inline-block">
                              {errors?.schedule?.message}
                            </span>
                          )}
                        </div>
                        <div className="form-note d-flex justify-content-between">
                          <span>/day</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <button className="btn btn-primary w-100" type="button" onClick={()=> connectLinkedin()}>Connect My Linkedin Account</button>
                    </div>
                    <div className="col-6">
                      <button className="btn btn-primary w-100">Create</button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
