import { useState } from 'react'
import { Form } from 'react-bootstrap'

const ToggleButton = ({ name, handleChange, defaultValue, className }) => {
  const [isChecked, setIsUnchecked] = useState(defaultValue)
  const handleToggle = () => {

    handleChange(name, 'enabled', !isChecked)
    setIsUnchecked(!isChecked)
  }
  return (
<div className={className}  >
      <Form>
    <Form.Check 
    
      type="switch"
      id="custom-switch"
      defaultChecked={defaultValue}
      onChange={() => {
        handleToggle()
      }}
    />

</Form>
</div>
  )
}

export default ToggleButton
