import { useEffect, useState } from "react";

const EducationItem = ({ index, data, saveChange }) => {
  let eduData = data;


  const handleChange = ( e) => {
    const targetName = e.target.name
    eduData = {...eduData, [targetName]:e.target.value}
    saveChange(index,eduData)
  }
  return (
    <div  className=' row gy-4 gx-4' key={index}>
      
      <div className='col-12'>
        <label className="form-label">
        Field of Study
          </label>
                <input
        placeholder='Field of Study - Institution'
        className="form-control"
        name='degree'
        value={data.degree}
        onChange={(e) => handleChange(e)}
      />
</div>
<div className='col-12'>
        <label className="form-label">
        Institution
          </label>
                <input
        placeholder='Institution'
        className="form-control"
        name='institution'
        value={data.institution}
        onChange={(e) => handleChange(e)}
      />
</div>
<div className='col-6 last-form-elements'>

<label className="form-label">
          From
          </label>
      <input
        placeholder='Date '
        className="form-control"
        name='dateFrom'
        value={data?.dateFrom}
        onChange={(e) => handleChange(e)}
      />
      </div>
     
         <div className='col-6'>
         <label className="form-label">
          To
          </label>
            <input
        placeholder='Date '
        className="form-control"
        name='dateTo'
        value={data?.dateTo}
        onChange={(e) => handleChange(e)}
      />
      </div>

        
    </div>
  )
}

export default EducationItem
