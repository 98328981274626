import React, { useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
// import CustomCardStyle3 from '../../../ui/CustomCard/CustomCardStyle3';
// import IconboxStyle3 from '../../../ui/Iconbox/IconboxStyle3';
import { decodeToken } from "../../../../../apiCalls/userToken";
import {
  getJobStatus,
  getLinkedinPostMessage,
  getTwitterPostMessage,
} from "../../../../../apiCalls/apiFetch";
import TerminalEditor from "../../dashboard/playground/terminal";
import Icon from "../../../ui/Icon";

export default function DocumentEditorGeneratePage({userData}) {
  const [twitterPost, setTwitterPost] = useState(null);
  const [linkedinPost, setLinkedinPost] = useState(null);
  const [userPrompt, setUserPrompt] = useState(null);
  const [userLinkedinPrompt, setUserLinkedinPrompt] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [PromptError, setPromptError] = useState(null);
  const [LinkedinPromptError, setLinkedinPromptError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  function WordCount(str) {
    return str.split(" ").length;
  }
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const onSubmit = async (data) => {
    setLoading(true)
    setPromptError(null);
    const PromptWordCOunt = WordCount(data.Prompt);
    if (PromptWordCOunt <= 500) {
      const { userId, role } = await decodeToken();
      if (userId && role === "User") {
        const id = toast.loading("Browsing the web for you");
        data.postOn = "Twitter";
        data.userId = userData._id;
        setUserPrompt(data?.Prompt);
        const res = await getTwitterPostMessage(data);
        if (res.status === "Success") {
          console.log("res",res)
        toast.update(id, {
          render: "Success",
          autoClose: 3000,
          type: "success",
          isLoading: false,
        });
        setTwitterPost(res.data);
        } else {
          toast.update(id, {
            render: res?.data,
            autoClose: 3000,
            type: "error",
            isLoading: false,
          });
        }
      } else {
        console.log("Not Authorized User!!!");
      }
    } else {
      setPromptError(
        `Word Count exceeds 500, the current word count is ${PromptWordCOunt}`
      );
    }
    setLoading(false)
  };
  // const pollJobStatus = async (jobId,id) => {
  //   const interval = setInterval(async () => {
  //     const res = await getJobStatus(jobId);
  //     console.log("What",res)
  //     if (res.status === 'Success') {
  //       console.log("Yesssss")
  //       toast.update(id, {
  //         render: "Success",
  //         autoClose: 3000,
  //         type: "success",
  //         isLoading: false,
  //       });
  //       setTwitterPost(res.data);
  //       clearInterval(interval);
  //     } else if (res.status === 'failed') {
  //       toast.update(id, {
  //         render: res?.data,
  //         autoClose: 3000,
  //         type: "error",
  //         isLoading: false,
  //       });
  //       clearInterval(interval);
  //     }else{
  //       console.log("Re-trying in 5 Seconds")
  //     }
  //   }, 15000); // Poll every 5 seconds
  // };
  const onSubmitLinkedin = async (data) => {
    setLoading(true)
    setLinkedinPromptError(null);
    const PromptWordCOunt = WordCount(data.Prompt);
    if (PromptWordCOunt <= 500) {
      const { userId, role } = await decodeToken();
      if (userId && role === "User") {
        const id = toast.loading("Browsing the web for you");
        data.postOn = "Linkedin";
        data.userId = userData._id;
        setUserLinkedinPrompt(data?.Prompt);
        const res = await getLinkedinPostMessage(data);
        if (res.status === "Success") {
          // pollJobStatus(res?.data,id)
          console.log("res",res)
          toast.update(id, {
            render: "Success",
            autoClose: 3000,
            type: "success",
            isLoading: false,
          });
          setLinkedinPost(res.data);
        } else {
          toast.update(id, {
            render: res?.data,
            autoClose: 3000,
            type: "error",
            isLoading: false,
          });
        }
      } else {
        console.log("Not Authorized User!!!");
      }
    } else {
      setPromptError(
        `Word Count exceeds 500, the current word count is ${PromptWordCOunt}`
      );
    }
    setLoading(false)
  };
  return (
    <div className="nk-editor-main">
      <div className="nk-editor-base">
        <ul className="nav nav-tabs nav-sm nav-tabs-s1 px-3">
          <li className="nav-item flex-navigation">
            <Icon className="icon" icon="twitter" />
            <button
              className={` ${
                activeTab === 1 ? "nav-link active" : "nav-link "
              }`}
              type="button"
              onClick={() => handleTabClick(1)}
            >
              Twitter Post
            </button>
          </li>
          <li className="nav-item flex-navigation">
            <Icon className="icon" icon="linkedin" />
            <button
              className={` ${
                activeTab === 2 ? "nav-link active" : "nav-link "
              }`}
              type="button"
              onClick={() => handleTabClick(2)}
            >
              Linkedin Post
            </button>
          </li>
        </ul>
        <div className="tab-content">
          {activeTab === 1 && (
            <>
              <div className="tab-pane fade show active">
                <form onSubmit={handleSubmit(onSubmit)} className="px-4 py-4">
                  <div className="row gy-4 gx-4">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">Select Post Type</label>
                        <div className="form-control-wrap">
                          <select
                            className="form-select"
                            {...register("type", {
                              required: "Type Required",
                            })}
                          >
                            <option selected>Browse for news</option>
                            <option>Be more creative</option>
                          </select>
                          {errors.type && (
                            <span className="text-danger mt-2 d-inline-block">
                              {errors.type?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">
                          What do you want to generate?
                        </label>
                        <div className="form-control-wrap">
                          <textarea
                            cols={30}
                            rows={4}
                            className="form-control"
                            defaultValue={
                              "Write an interesting post about AI in the gaming industry"
                            }
                            {...register("Prompt", {
                              required: "Prompt is required",
                            })}
                          />
                          {(errors.Prompt || PromptError) && (
                            <span className="text-danger mt-2 d-inline-block">
                              {errors?.Prompt?.message || PromptError}
                            </span>
                          )}
                        </div>
                        <div className="form-note d-flex justify-content-end">
                          <span>0/500 Characters</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="PrimaryKeywords" className="form-label">
                          Primary Keywords
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="chatgpt, openai, facebook"
                          />
                        </div>
                        <div className="form-note d-flex justify-content-between">
                          <span>Separated with a comma</span>
                          <span>0/10</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Language</label>
                        <div className="form-control-wrap">
                          <select className="form-select">
                            <option selected>English US</option>
                            {/* <option>Español</option>
                            <option>Françaisy</option> */}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Select Tone</label>
                        <div className="form-control-wrap">
                          <select className="form-select">
                            <option selected>Friendly</option>
                            <option>Creative</option>
                            <option>Funny</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-6">
                      <div className="form-group d-flex align-items-center flex-row-reverse">
                        <label
                          htmlFor="Variant"
                          className="form-label ms-3 mb-0 flex-grow-1"
                        >
                          Variant
                        </label>
                        <div className="form-control-wrap w-70px">
                          <input
                            id="Variant"
                            type="number"
                            className="form-control"
                            defaultValue={1}
                            min="0"
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col-6">
                      <button className="btn btn-primary w-100" disabled={loading}>
                        {loading? <>Loading</>:<>   Generate</>}
                     
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {activeTab === 2 && (
            <div className="tab-pane fade show active">
              <form
                onSubmit={handleSubmit(onSubmitLinkedin)}
                className="px-4 py-4"
              >
                <div className="row gy-4 gx-4">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">Select Post Type</label>
                      <div className="form-control-wrap">
                        <select
                          className="form-select"
                          {...register("type", {
                            required: "Type Required",
                          })}
                        >
                          <option selected>Browse for news</option>
                          <option>Be more creative</option>
                        </select>

                        {(errors.Prompt || LinkedinPromptError) && (
                          <span className="text-danger mt-2 d-inline-block">
                            {errors?.Prompt?.message || LinkedinPromptError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">
                        What do you want to generate?
                      </label>
                      <div className="form-control-wrap">
                        <textarea
                          cols={30}
                          rows={4}
                          className="form-control"
                          defaultValue={
                            "Write an interesting post about AI in the gaming industry"
                          }
                          {...register("Prompt", {
                            required: "Prompt is required",
                          })}
                        />
                        {(errors.Prompt || PromptError) && (
                          <span className="text-danger mt-2 d-inline-block">
                            {errors?.Prompt?.message || PromptError}
                          </span>
                        )}
                      </div>
                      <div className="form-note d-flex justify-content-end">
                        <span>0/500 Characters</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="PrimaryKeywords" className="form-label">
                        Primary Keywords
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          defaultValue="chatgpt, openai, facebook"
                        />
                      </div>
                      <div className="form-note d-flex justify-content-between">
                        <span>Separated with a comma</span>
                        <span>0/10</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Language</label>
                      <div className="form-control-wrap">
                        <select className="form-select">
                          <option selected>English US</option>
                          {/* <option>Español</option>
                             <option>Françaisy</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Select Tone</label>
                      <div className="form-control-wrap">
                        <select className="form-select">
                          <option selected>Friendly</option>
                          <option>Creative</option>
                          <option>Funny</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                      <button className="btn btn-primary w-100" disabled={loading}>
                        {loading? <>Loading</>:<>   Generate</>}
                     
                      </button>
                    </div>
                </div>
              </form>
            </div>
            // <div className="tab-pane fade show active">
            //   <div className="px-3 py-4">
            //     <div className="d-flex flex-wrap justify-content-between mb-3">
            //       <h6 className="mb-0 me-3">Generation History</h6>
            //       <div className="fs-11px">
            //         <span className="text-dark">1763</span> words left.{' '}
            //         <a href="#">Go Unlimited</a>
            //       </div>
            //     </div>
            //     <div className="row g-4">
            //       <div className="col-12">
            //         <CustomCardStyle3
            //           category="Social Media Post"
            //           text="Hey everyone! Have you met ChatGPT? As an AI language model, I'm trained to answer your questions and have a conversation with you! Ask me anything, and let's get chatting! 😊"
            //           date="Feb 23, 2023"
            //           time="05:23 PM"
            //           wordNumber={42}
            //         />
            //       </div>
            //       <div className="col-12">
            //         <CustomCardStyle3
            //           category="Social Media Post"
            //           text="Are you tired of Googling for answers? Meet ChatGPT - your one-stop solution for all your queries! I'm an AI language model trained to provide you with accurate and informative responses. Try me out! 💬"
            //           date="Feb 23, 2023"
            //           time="06:23 PM"
            //           wordNumber={56}
            //         />
            //       </div>
            //       <div className="col-12">
            //         <CustomCardStyle3
            //           category="Social Media Post"
            //           text="OpenAI is changing the game for data-driven innovation. With its cutting-edge technology, we can harness the power of data in ways we never thought possible."
            //           date="Feb 23, 2023"
            //           time="07:23 PM"
            //           wordNumber={78}
            //         />
            //       </div>
            //     </div>
            //   </div>
            // </div>
          )}
        </div>
      </div>
      <div className="nk-editor-body">
        <TerminalEditor
          twitterPost={twitterPost}
          linkedinPost={linkedinPost}
          userPrompt={userPrompt}
          userLinkedinPrompt={userLinkedinPrompt}
        />
      </div>
    </div>
  );
}
