import {Link} from 'react-router-dom';
import React, { useState } from 'react';
import Icon from '../Icon';

export default function DropDown({ subMenuTtitle, subMenuIcon, subMenu }) {
  // Mobile Toggle
  const [mobileToggle, setMobileToggle] = useState(false);
  let pathname = window.location.pathname
  return (
    <li
      className={`nk-menu-item has-sub ${
        subMenu.some(item => item.to === pathname || mobileToggle)
          ? 'active'
          : ''
      }`}
    >
      <span
        className="nk-menu-link nk-menu-toggle"
        style={{ cursor: 'pointer' }}
        onClick={() => setMobileToggle(!mobileToggle)}
        // to={subMenu[0].to}
      >
        <span className="nk-menu-icon">
          <Icon icon={subMenuIcon} className="icon" />
        </span>
        <span className="nk-menu-text">{subMenuTtitle}</span>
      </span>
      <ul
        className={`nk-menu-sub ${mobileToggle ? 'd-block' : ''}`}
        style={{
          display: `${
            subMenu.some(item => item.to === pathname) ? 'block' : 'none'
          }`,
        }}
      >
        {subMenu.map((sumItem, index) => (
          <li
            className={`nk-menu-item ${
              pathname === sumItem.to ? 'active' : ''
            }`}
            key={index}
          >
            <Link to={sumItem.to} className="nk-menu-link">
              <span className="nk-menu-text">{sumItem.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}
