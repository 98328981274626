import axios from "axios";


  export const updateUserData = async (id, updateData) => {

    try {
      const { data } = await axios.put(`/api/Users/${id}`, updateData);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const userEditCampaign = async ({id,dataUpdate}) => {
    try {
      const { data } = await axios.put(`/api/users/editCampaign/${id}`,{dataUpdate,
        // headers: {
        //   "Content-Type": "application/json",
        //   Authorization: `Bearer ${localStorage.getItem("token")}`,
        // },
      });
      return data;
    } catch (error) {
      return null;
    }
  };

  export const userEditResume = async (dataUpdate) => {
    const id = dataUpdate?._id
    try {
      const { data } = await axios.put(`/api/users/editResume/${id}`,{dataUpdate});
      return data;
    } catch (error) {
      return null;
    }
  };

  export const updateReviewerData = async (id, updateData) => {
  
    try {
      const { data } = await axios.put(`/api/reviewers/${id}`, updateData);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const updateAdminsData = async (id, updateData) => {
    try {
      const { data } = await axios.put(`/api/admin/${id}`, updateData);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const updatePublishersData = async (id, updateData) => {
    try {
      const { data } = await axios.put(`/api/publisher/${id}`, updateData);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const changeUserPassword = async (updateData) => {
    try {
      const { data } = await axios.put(`/api/authUsers/changePassword`, updateData);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const changeReviewerPassword = async (updateData) => {
    try {
      const { data } = await axios.put(`/api/authEditors/changePassword`, updateData);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const changeAdminPassword = async (updateData) => {
    try {
      const { data } = await axios.put(`/api/authAdmin/changePassword`, updateData);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const changePublisherPassword = async (updateData) => {
    try {
      const { data } = await axios.put(`/api/authPublishers/changePassword`, updateData);
      return data;
    } catch (error) {
      return null;
    }
  };

  export const reviewerApprovePaper = async (id,paper, comment) => {
    try {
      const { data } = await axios.put(`/api/reviewers/approvePaper/${id}`,{paperId: paper, comment});
      return data;
    } catch (error) {
      return null;
    }
  };
  export const reviewerResubmitPaper = async (id,paper, comment) => {
    try {
      const { data } = await axios.put(`/api/reviewers/resubmitPaper/${id}`,{paperId: paper, comment});
      return data;
    } catch (error) {
      return null;
    }
  };


  export const reviewerRejectPaper = async (id,paper, comment) => {
    try {
      const { data } = await axios.put(`/api/reviewers/rejectPaper/${id}`,{paperId: paper, comment});
      return data;
    } catch (error) {
      return null;
    }
  };
  export const adminApproveScreeningPaper = async (id,paper,comment) => {
    try {
      const { data } = await axios.put(`/api/admin/approvePaper/${id}`,{paperId: paper, comment});
      return data;
    } catch (error) {
      return null;
    }
  };
  export const adminResubmitScreeningPaper = async (id,paper, comment) => {
    try {
      const { data } = await axios.put(`/api/admin/resubmitPaper/${id}`,{paperId: paper, comment});
      return data;
    } catch (error) {
      return null;
    }
  };

  export const adminRejectScreeningPaper = async (id,paper, comment) => {
    try {
      const { data } = await axios.put(`/api/admin/rejectPaper/${id}`,{paperId: paper, comment});
      return data;
    } catch (error) {
      return null;
    }
  };
  export const adminAddDOI = async (id,doi) => {
    try {
      const { data } = await axios.put(`/api/papers/addDoi/${id}`,{doi});
      return data;
    } catch (error) {
      return null;
    }
  };

  export const publisherPublishPaper = async (id,data1) => {
    try {
      const { data } = await axios.put(`/api/publisher/publishPaper/${id}`,data1);
      return data;
    } catch (error) {
      return null;
    }
  };

  export const userTranferCopyrights = async (id,email) => {

    try {
      const { data }  = await axios.post("/api/payment/checkDiscount", { id, email });
      return data
    } catch (error) {
      return null;
    }
  };

  
  
  