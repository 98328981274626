export function Template1Send({ a }) {
  // const ctx = useContext(BuilderContext)
  const education = a?.Education;
  const skills = a?.KeySkills;
  const profile = a?.Profile;
  const others = a?.Other;
  // const contact = a?.Contact')
  const projects = a?.Projects;
  const socials = a?.socials;
  const certifications = a?.Certifications;
  const employment = a?.Employment;
  return (
    <div class="temp1">
      <div class="temp1-inner">
        <div class="temp1-container">
          <div class="temp1-header">
            <h1>{profile?.name}</h1>
            <p>{profile?.email}</p>
            <p>{profile?.phoneNumber}</p>
            <p>
              <a href={socials?.linkedin}>{socials?.linkedin}</a>
            </p>
          </div>
          {profile?.display && profile?.about && profile.about !== "" && (
            <div class="temp1-education">
              <div class="temp1-section-title">
                <h3>Summary</h3>
              </div>

              <div class="temp1-education-flex">
                <div>
                  <p class="temp1-summary">{profile.about} </p>
                </div>
              </div>
            </div>
          )}
          {education?.display && (
            <div class="temp1-education">
              <div class="temp1-section-title">
                <h3>Education</h3>
              </div>
              {education?.items.map((item, index) => (
                <div class="temp1-education-flex">
                  <div>
                    {item?.degree && item?.degree !== "" && (
                      <p class="temp1-education-text">{item?.degree} </p>
                    )}
                    {item?.institution && item?.institution !== "" && (
                      <p class="temp1-education-text">{item?.institution} </p>
                    )}
                  </div>
                  <div class="float-right">
                    {item.dateFrom &&
                      item.dateTo &&
                      item?.dateFrom !== "" &&
                      item?.dateTo !== "" && (
                        <p>{`${item.dateFrom} - ${item.dateTo}`}</p>
                      )}
                    {item.gpa && item?.gpa !== "" && (
                      <p>{`GPA: ${item.gpa}`}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* <!-- Work Experience --> */}
          {employment?.display && (
            <div class="temp1-work-experience">
              <div class="temp1-section-title">
                <h3>Work Experience</h3>
              </div>
              {employment?.items.map((item, index) => (
                <div class="temp1-blocks" key={index}>
                  {item?.position && (
                    <span class="temp1-job-title">{item.position}</span>
                  )}
                  {item?.dateFrom && item?.dateTo && (
                    <span class="temp1-job-duration">{`${item.dateFrom} - ${item.dateTo}`}</span>
                  )}

                  <div>
                    <ul>
                      {item?.responsibilities?.map((item, index) => (
                        <li key={index}> {item} </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}
          {projects?.display && (
            <div class="temp1-work-experience">
              <div class="temp1-section-title">
                <h3>Projects</h3>
              </div>
              {projects?.items.map((item, index) => (
                <div class="temp1-blocks" key={index}>
                  <span class="temp1-job-title">{item.title}</span>
                  {item?.dateFrom && (
                    <span class="temp1-job-duration">{`${item?.dateFrom} - ${item?.dateTo}`}</span>
                  )}
                  <div>
                    <ul>
                      {item?.description?.map((item, index) => (
                        <li key={index}> {item} </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}
          {certifications?.display && (
            <div class="temp1-work-experience">
              <div class="temp1-section-title">
                <h3>Certifications</h3>
              </div>
              <ul class="temp1-skills-container">
                {certifications?.items?.map((item, index) => (
                  <li class="temp1-skills-item" key={index}>
                    {" "}
                    {item}{" "}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {others?.display && (
            <>
              <div class="temp1-work-experience">
                <div class="temp1-section-title">
                  <h3>Work Experience</h3>
                </div>
                {employment?.items.map((item, index) => (
                  <div class="temp1-blocks" key={index}>
                    {item?.position && (
                      <span class="temp1-job-title">{item.position}</span>
                    )}
                    {item?.dateFrom && item?.dateTo && (
                      <span class="temp1-job-duration">{`${item.dateFrom} - ${item.dateTo}`}</span>
                    )}

                    <div>
                      <ul>
                        {item?.responsibilities?.map((item, index) => (
                          <li key={index}> {item} </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
              {others?.items?.map((item, index) => (
                <div class="temp1-work-experience" key={index}>
                  <div class="temp1-section-title">
                    <h3>{item?.name}</h3>
                  </div>

                  <div class="temp1-blocks">
                    {item?.position && (
                      <span class="temp1-job-title">{item.position}</span>
                    )}
                    {item?.dateFrom &&
                      item?.dateFrom !== "" &&
                      item?.dateTo !== "" && (
                        <span class="temp1-job-duration">{`${item?.dateFrom} - ${item?.dateTo}`}</span>
                      )}
                    <div>
                      <ul>
                        {item?.details?.map((i, index) => (
                          <li key={index}> {i} </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

          {skills?.display && (
            <div class="temp1-work-experience">
              <div class="temp1-section-title">
                <h3>Skills</h3>
              </div>

              <div className="temp1-skills-container">
  {skills?.text?.map((item, index)=>(
<div className="temp1-skills-item" key={index}><p> {item} </p></div>
         ) )}
</div>

            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function Template2Send({ a }) {
  const education = a?.Education;
  const skills = a?.KeySkills;
  const projects = a?.Projects;
  const profile = a?.Profile;
  // const contact = a?.Contact?
  const socials = a?.Socials;
  const others = a?.Other;
  const certifications = a?.Certifications;
  const employment = a?.Employment;
  return (
    <div class="template2">
      <div class="template2-inner">
        <div class="temp2-main">
          <header class="temp2-title">
            <h1>{profile?.name}</h1>
            <span class="temp2-subtitle">{profile?.profession}</span>
          </header>
          {/* Summary  */}
          {profile?.display && profile?.about && profile.about !== "" && (
            <section class="temp2-main-block concise temp2-header-container">
              <h2> Summary</h2>

              <section class="temp2-blocks">
                <div class="temp2-date">
                  {/* <span>{item?.date?.from}</span><span>{item?.date?.to}</span> */}
                </div>
                <div class="temp2-decorator"></div>
                <div class="temp2-summary-details">
                  <div>{profile?.about}</div>
                </div>
              </section>
            </section>
          )}
          {/* Education */}
          {education?.display && (
            <section class="temp2-main-block concise temp2-header-container">
              <h2>
                <i class="fa fa-graduation-cap"></i> Education
              </h2>
              {education?.items.map((item, index) => (
                <section class="temp2-blocks">
                  <div class="temp2-date">
                    <span>{item?.dateFrom}</span>
                    <span>{item?.dateTo}</span>
                  </div>
                  <div class="temp2-decorator"></div>
                  <div class="temp2-details">
                    <div class="temp2-header-containers">
                      <h3>{item?.degree}</h3>
                      <span class="temp2-place">{item?.institution}</span>
                      {item?.location && item?.location === "" && (
                        <span class="temp2-location">{item?.location}</span>
                      )}
                    </div>
                    <div>{item?.details}</div>
                  </div>
                </section>
              ))}
            </section>
          )}
          {/* Employment */}
          {employment?.display && (
            <section class="temp2-main-block temp2-header-container">
              <h2>
                <i class="temp2-fa temp2-fa-suitcase"></i> Experiences
              </h2>
              {employment?.items.map((item, index) => (
                <section class="temp2-blocks" key={index}>
                  <div class="temp2-date">
                    <span>{item.dateFrom}</span>
                    <span>{item.dateTo}</span>
                  </div>
                  <div class="temp2-decorator"></div>
                  <div class="temp2-details">
                    <div class="temp2-header-containers">
                      <h3>{item.position}</h3>
                      <span class="temp2-place">{item.location}</span>
                      {item?.location && item?.location === "" && (
                        <span class="temp2-location">{item?.location}</span>
                      )}
                    </div>

                    <ul>
                      {item?.responsibilities?.map((item, index) => (
                        <li key={index}> {item} </li>
                      ))}
                    </ul>
                  </div>
                </section>
              ))}
            </section>
          )}
          {/* Projects  */}
          {projects?.display && (
            <section class="temp2-main-block temp2-header-container">
              <h2>
                <i class="temp2-fa fa-folder-open"></i> Projects
              </h2>
              {projects?.items.map((item, index) => (
                <section class="temp2-blocks" key={index}>
                  <div class="temp2-date">
                    <span>{item.dateFrom}</span>
                    <span>{item.dateTo}</span>
                  </div>
                  <div class="temp2-decorator"></div>
                  <div class="temp2-details">
                    <div class="temp2-header-containers">
                      <h3>{item.title}</h3>
                      <span class="temp2-place">{item.location}</span>
                      {item?.location && item?.location === "" && (
                        <span class="temp2-location">{item?.location}</span>
                      )}
                    </div>

                    <ul>
                      {item?.description?.map((item, index) => (
                        <li key={index}> {item} </li>
                      ))}
                    </ul>
                  </div>
                </section>
              ))}
            </section>
          )}
          {/* Others  */}
          {others?.items?.map((item, index) => (
            <div>
              {item?.display && (
                <section class="temp2-main-block temp2-header-container">
                  <h2>
                    <i class="temp2-fa fa-folder-open"></i> {item?.name}
                  </h2>

                  <section class="temp2-blocks" key={index}>
                    <div class="temp2-date">
                      {item.dateFrom &&
                        item.dateFrom &&
                        item.dateFrom !== "" &&
                        item.dateFrom !== "" && (
                          <>
                            <span>{item.dateFrom}</span>
                            <span>{item.dateTo}</span>
                          </>
                        )}
                    </div>
                    <div class="temp2-decorator"></div>
                    <div class="temp2-details">
                      <div class="temp2-header-containers">
                        {item.position && <h3>{item.position}</h3>}

                        {item?.location && item?.location === "" && (
                          <span class="temp2-location">{item?.location}</span>
                        )}
                      </div>

                      <ul>
                        {item?.details?.map((item, index) => (
                          <li key={index}> {item} </li>
                        ))}
                      </ul>
                    </div>
                  </section>
                </section>
              )}
            </div>
          ))}
        </div>
        <aside class="temp2-sidebar">
          {profile?.display && (
            <div class="temp2-side-block temp2-contact">
              <h1>Contact Info</h1>
              <ul>
                {/* <li><i class="fa fa-globe"></i>{socials.items}</li> */}
                <li>
                  <i class="fa fa-envelope"></i>
                  {profile?.email}
                </li>
                <li>
                  <i class="fa fa-phone"></i> {profile?.phoneNumber}
                </li>
                {socials?.items?.map((a, i) => {
                  if (a?.enabled) return <li>{a.url}</li>;
                  return null;
                })}
              </ul>
            </div>
          )}
          {skills?.display && (
            <div class="temp2-side-block temp2-skills">
              <h1>Skills</h1>
              <ul>
                {skills?.text?.map((item, index) => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
          )}
          {certifications?.display && (
            <div class="temp2-side-block temp2-skills">
              <h1>Certifications</h1>
              <ul>
                {certifications?.items?.map((item, index) => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </aside>
      </div>
    </div>
  );
}
