import React from "react";
import Template1 from "./components/templates/template1";
import Template2 from "./components/templates/template2";
// import Template3 from "./components/templates/template3";
// Create Document Component

const PreviewScreen = ({template, a}) => {
  // const ctx = useContext(BuilderContext);
  // console.log("id",template)
  return (
    <>
    {template === 1 ?
     <>
     <Template1 a={a}/>
    </>:
      <Template2 a={a} />
    }
    </>
  );
};

export default PreviewScreen;
