import { Link } from "react-router-dom";
import React, { useState } from 'react';
import navData from './navData.json';
import Icon from '../Icon';
import DropDown from './DropDown';
import UpgradeCard from '../CustomCard/UpgradeCard';

export default function SideNav({userData}) {
  const [compact, setCompact] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);
  const [toggleAvata, setToggleAvata] = useState(false);
  const [toggleCreate, setToggleCreate] = useState(false);
  const { menuData } = navData;
  let pathname = window.location.pathname

  // {
  //   "to": "/pricing-plans",
  //   "title": "Pricing Plans",
  //   "icon": "sign-usdc",
  //   "heading": false
  // },
  // {
  //   "to": "/subscription",
  //   "title": "Subscription",
  //   "icon": "wallet",
  //   "heading": false
  // },
  // {
  //   "to": "/forgot-password",
  //   "title": "Forgot Password",
  //   "icon": "edit",
  //   "heading": false
  // }
  return (
    <>
      <div
        className={mobileActive ? 'sidebar-overlay active' : 'sidebar-overlay'}
        onClick={() => setMobileActive(!mobileActive)}
      />
      <div className="nk-header nk-header-fixed">
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-header-logo ms-n1">
              <div className="nk-sidebar-toggle me-1">
                <button
                  className="btn btn-sm btn-zoom btn-icon sidebar-toggle d-sm-none"
                  onClick={() => setMobileActive(!mobileActive)}
                >
                  <em className="icon ni ni-menu"> </em>
                </button>
                <button
                  className="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex"
                  onClick={() => setMobileActive(!mobileActive)}
                >
                  <em className="icon ni ni-menu"> </em>
                </button>
              </div>
              <Link to="/" className="logo-link">
                <div className="logo-wrap">
                  <img
                    className="logo-img logo-dark"
                    src='/images/resumeZ-dark-logo.png'
                    alt="Logo"
                    height={30}
                    width={144}
                  />
                </div>
              </Link>
            </div>
            <div className="nk-header-tools">
              <ul className="nk-quick-nav ms-2">
              <li className="dropdown d-inline-flex">
                
              <div className="btn btn-primary btn-sm"
                onClick={() => setToggleCreate(!toggleCreate)}>
                {/* {leftIconClass && <Icon icon={leftIconClass} className="icon" />} */}
                <span>Create</span>
                <Icon icon='chevron-down' className="icon" />
              </div>
              {toggleCreate && (
                    <div
                      className="dropdown-menu dropdown-menu-md rounded-3 show end-0"
                      style={{ top: '100%' }}
                    >
                      <div className="dropdown-content py-3">
                      <ul
                    className={` ${
                      true ? "show" : "" 
                    }`}
                    // style={{ display: `${mobileToggle ? 'block' : 'none'}` }}
                  >
                    <li>
                      <Link
                        className="dropdown-item link-base py-2 px-4"
                         to="/create-campaign">
                          Create Campaign
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item link-base py-2 px-4"
                        to="/">Profile Settings
                      </Link>
                    </li>

                  </ul>
                      </div>
                    </div>
                  )}
              </li>
                <li className="dropdown d-inline-flex">
                  <span
                    className="d-inline-flex"
                    onClick={() => setToggleAvata(!toggleAvata)}
                  >
                    <div className="media media-md media-circle media-middle text-bg-primary">
                      <img 
                                src={userData?.profilePicture || "images/avatar/a.png" }
                                alt="Profile"
                                referrerpolicy='no-referrer'
                                />
                    </div>
                  </span>
                  {toggleAvata && (
                    <div
                      className="dropdown-menu dropdown-menu-md rounded-3 show end-0"
                      style={{ top: '100%' }}
                    >
                      <div className="dropdown-content py-3">
                        <UpgradeCard  userData={userData}/>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`nk-sidebar nk-sidebar-fixed ${
          mobileActive ? 'sidebar-active' : ''
        } ${compact ? 'is-compact' : ''}`}
        id="sidebar"
      >
        <div className="nk-compact-toggle">
          <button
            className={`btn btn-xs btn-outline-light btn-icon compact-toggle text-light bg-white rounded-3 ${
              compact ? 'active' : ''
            }`}
            onClick={() => setCompact(!compact)}
          >
            <Icon icon="chevron-left" className="icon off" />
            <Icon icon="chevron-right" className="icon on" />
          </button>
        </div>
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-sidebar-brand">
            <Link to="/" className="logo-link">
              <div className="logo-wrap">
                <img className="logo-img logo-dark" src="/images/resumeZ-dark-logo.png" alt="Logo" />
                <img
                  className="logo-img logo-icon"
                  src="/images/resumeZ-dark-logo.png"
                  alt="Logo Icon"
                  height={27}
                  width={40}
                />
              </div>
            </Link>
          </div>
        </div>
        <div className="nk-sidebar-element nk-sidebar-body">
          <div
            className="nk-sidebar-content h-100 simplebar-scrollable-y"
            data-simplebar="init"
          >
            <div className="simplebar-wrapper" style={{ margin: '0px' }}>
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer" />
              </div>
              <div className="simplebar-mask">
                <div
                  className="simplebar-offset"
                  style={{ right: '0px', bottom: '0px' }}
                >
                  <div
                    className="simplebar-content-wrapper"
                    tabIndex={0}
                    role="region"
                    aria-label="scrollable content"
                    style={{ height: '100%', overflow: 'hidden scroll' }}
                  >
                    <div
                      className="simplebar-content"
                      style={{ padding: '0px' }}
                    >
                      <div className="nk-sidebar-menu">
                        <ul className="nk-menu">
                          {menuData.map((item, index) => (
                            <>
                              {item?.subMenu ? (
                                <DropDown
                                  subMenuTtitle={item.title}
                                  subMenuIcon={item.icon}
                                  subMenu={item?.subMenu}
                                  key={index}
                                />
                              ) : (
                                <li
                                  className={`${
                                    item.heading
                                      ? 'nk-menu-heading'
                                      : `nk-menu-item ${
                                          pathname === item.to ? 'active' : ''
                                        }`
                                  }`}
                                  key={index}
                                >
                                  {item.heading ? (
                                    <h6 className="overline-title">
                                      {item.title}
                                    </h6>
                                  ) : (
                                    <Link
                                      to={item.to}
                                      className="nk-menu-link"
                                    >
                                      <span className="nk-menu-icon">
                                        <Icon
                                          icon={item.icon}
                                          className="icon"
                                        />
                                      </span>
                                      <span className="nk-menu-text">
                                        {item.title}
                                      </span>
                                    </Link>
                                  )}
                                </li>
                              )}
                            </>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-sidebar-element nk-sidebar-footer">
          <div className="nk-sidebar-footer-extended pt-3">
            <UpgradeCard userData={userData}/>
          </div>
        </div>
      </div>
    </>
  );
}
