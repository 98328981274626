import React, { useEffect, useState } from "react";
import { getUsersData } from "../../../../../apiCalls/apiFetch";
import { decodeToken } from "../../../../../apiCalls/userToken";
import UserDashBoardChangePassword from "./password";

const Password = ({ props }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const { userId, role } = await decodeToken();
      if (userId && role === "User") {
        const data = await getUsersData(userId);
        if (data) setUserData(data);
      }
    };
    fetch();
  }, []);

  return <UserDashBoardChangePassword userData={userData} />;
};

export default Password;
