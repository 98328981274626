import React, { useState, useEffect } from "react";
import SideNav from "../../../../ui/SideNav";
import Footer from "../../../../ui/Footer";
import { decodeToken } from "../../../../../../apiCalls/userToken";
import {
  getUsersData,
  getusersResumes,
} from "../../../../../../apiCalls/apiFetch";
import {useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

function ShowResumeTemplatesDashboard() {
  const [userData, setUserData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [resumes, setResumes] = useState([]);
  const navigate = useNavigate();

  const navigateTo = (template, rId) => {
    if (rId) {
      navigate(`/resume/?template=${template}&id=${rId}`);
    } else {
      navigate(`/resume/?template=${template}`);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      setLoadingData(true);
      const token = await decodeToken();
      // if(!token) navigate("/login")
      if (token?.userId && token?.role === "User") {
        const data = await getUsersData(token?.userId);

        if (data) setUserData({ ...data, profilePicture: token?.profilePicture });
        const data2 = await getusersResumes(token?.userId);
        if (data2) setResumes(data2);
      }
      setLoadingData(false);
    };
    fetch();
  }, [navigate]);

  return (
    <div className="nk-app-root" data-sidebar-collapse="lg">
      <div className="nk-main">
        <SideNav userData={userData} />
        <div className="nk-wrap">
          <div className="nk-content">
            <div className="container-xl">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-editor-main">
                    <div className="nk-editor-base my-resumes-container">
                      <h3> My Resumes</h3>
                      {loadingData ? (
                        <p>Loading...</p>
                      ) : (
                        <>
                          {resumes && resumes.length === 0 ? (
                            <p>
                              You have no resumes, create one from the templates
                            </p>
                          ) : (
                            <div>
                              {resumes.map((item, index) => {
                                return (
                                  <div>
                                    <button
                                      onClick={() =>
                                        navigateTo(item.template, item._id)
                                      }
                                    >
                                      {item.name}
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </>
                      )}
                      <div />
                    </div>

                    <div className="nk-editor-body resume-container">
                      <div className="templates-container">
                        <h3>Templates</h3>
                        <div>
                          <Row xs={1} md={2} lg={2}>
                            <Col>
                              <button onClick={() => navigateTo(1)}>
                                <div className="templates-img template-card">
                                  <img
                                    src="/images/resume-templates/template1.jpg"
                                    alt="template-1"
                                  />
                                </div>
                              </button>
                            </Col>
                            <Col>
                              {" "}
                              <button onClick={() => navigateTo(2)}>
                                <div className="templates-img template-card">
                                  <img
                                    src="/images/resume-templates/template2.jpg"
                                    alt="template-2"
                                  />
                                </div>
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ShowResumeTemplatesDashboard;
