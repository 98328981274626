import Footer from '../../ui/Footer';
import { Link } from 'react-router-dom';
import LoginContainer from './login.jsx';

export default function RootLayout() {
  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <div className="nk-wrap has-shape flex-column overflow-hidden">
          <div className="nk-shape bg-shape-blur-a start-0 top-0"></div>
          <div className="nk-shape bg-shape-blur-b end-0 bottom-0"></div>
          <div className="text-center pt-5">
            <Link href="/" className="logo-link">
              {/* <div className="logo-wrap">
                <img
                  className="logo-img logo-dark"
                  src="/images/resumeZ-dark-logo.png"
                  srcSet="/images/resumeZ-dark-logo.png"
                  alt=""
                />
                <img
                  className="logo-img logo-icon"
                  src="/images/resumeZ-logo.png"
                  srcSet="/images/resumeZ-logo.png"
                  alt=""
                />
              </div> */}
              <img
                  className=""
                  src="/images/resumeZ-dark-logo.png"
                  srcSet="/images/resumeZ-dark-logo.png"
                  width={300}
                  alt=""
                />
            </Link>
          </div>
          <LoginContainer/>
          <Footer />
        </div>
      </div>
    </div>
  );
}
