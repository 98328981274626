import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { decodeToken } from "../../../../../apiCalls/userToken.jsx";
import { changeUserPassword } from "../../../../../apiCalls/apiPut.jsx";

const UserDashBoardChangePassword = () => {
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = async (data) => {
    setError(null);
    if (data.newPassword === data.confirmPassword) {
      const { userId, role } = await decodeToken();
      if (userId && role === "User") {
        data.id = userId;
        const id = toast.loading("updating Password...");
        const res = await changeUserPassword(data);
        // console.log("res,", res);
        if (res.status === "Success") {
          toast.update(id, {
            render: "Success",
            autoClose: 3000,
            type: "success",
            isLoading: false,
          });
        } else if (res.status === "error") {
          toast.update(id, {
            render: res.data,
            autoClose: 5000,
            type: "error",
            isLoading: false,
          });
        }
      } else {
        toast("Sign in or You are not a User");
      }
    } else {
      setError("Confirm password doesn't match");
    }
  };
  return (
    <div className="col-md-7 col-lg-8 col-xl-9">
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label>Old Password</label>
              <input
                type="password"
                className="form-control"
                {...register("oldPassword", {
                  required: "Current password is required",
                })}
              />
              {errors.oldPassword && (
                <span className="text-danger mt-2 d-inline-block">
                  {errors.oldPassword?.message}
                </span>
              )}
            </div>
            <div className="form-group">
              <label>New Password</label>
              <input
                type="password"
                className="form-control"
                {...register("newPassword", {
                  required: "newPassword is required",
                })}
              />
              {errors.newPassword && (
                <span className="text-danger mt-2 d-inline-block">
                  {errors.newPassword?.message}
                </span>
              )}
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                className="form-control"
                {...register("confirmPassword", {
                  required: "confirmPassword is required",
                })}
              />
              {(errors.confirmPassword || error) && (
                <span className="text-danger mt-2 d-inline-block">
                  {errors.confirmPassword?.message || error}
                </span>
              )}
            </div>
            <div className="submit-section">
              <button type="submit" className="btn btn-primary submit-btn">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserDashBoardChangePassword;
