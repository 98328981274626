import {  useState } from 'react'
import { Hide } from './Icons/Hide'
import Show from './Icons/Show'
import { Add } from './Icons/Add'
import { Remove } from './Icons/Remove'
import { Up } from './Icons/Up'
import { Down } from './Icons/Down'
const ProjectItem = ({ data, index,savechanges, className }) => {

  const [isToggled, setIsToggled] = useState(true)
  let projectData = data
  let description = data.description
  const handleChange = ( e) => {
    const targetName = e.target.name
    projectData = {...projectData, [targetName]:e.target.value}
    savechanges(index,projectData)
  }

  return (
    <div className='flex pt-10px'>
      <div className='look-btn-emp'>
      {!isToggled ? (
        <Hide
        handleClick={() => {
            setIsToggled(true)
          }}
        />
      ) : (
        <Show
        handleClick={() => {
            setIsToggled(!isToggled)
          }}
          />
      )}
      </div>
      <div>
<div className='col-12'>
        <input
      className={className}
      placeholder='Postion Title - Company'
        isDisabled={isToggled}
        name='title'
        defaultValue={projectData.title}
        onChange={(e) => handleChange(e)}
        />
       </div>
      {!isToggled && (
       <>
       <div className='row '>
        <div className='col-6 gy-2'>
          <label className="form-label">
          Date From 
            </label>
          <input
            className={className}
            placeholder='Date From - To'
            name='dateFrom'
            defaultValue={projectData?.dateFrom}
            onChange={(e) => handleChange(e)}
          />
           </div>
           <div className='col-6 gy-2'>
          <label className="form-label">
          Date  To
            </label>
          <input
            className={className}
            placeholder='Date From - To'
            name='dateTo'
            defaultValue={projectData?.dateTo}
            onChange={(e) => handleChange(e)}
          />
           </div>
           </div>
        
          <div className='col-12 gy-2'>
          <label className="form-label">
          Location
            </label>
          <input
            className={className}
            placeholder='Position Description'
            name='location'
            defaultValue={projectData.location}
            onChange={(e) => handleChange(e)}
          />
          </div>
          <div className='col-12 gy-2 last-form-member'>
            <div className='flex justify-items pt-10px ml-20px '>
          <label className="form-label">
          Description
            </label>
            <div className='flex'>
        <Add color='#d1d5db'     
         handleClick={() =>{
       
          description = [...description,"New description"]
          projectData = {...projectData, description}
          savechanges(index,projectData)
        }} />

        <Remove color='#d1d5db' handleClick={() =>{
          description = description.filter((a,i) => i !== description.length - 1)

          projectData = {...projectData, description}
          savechanges(index,projectData)
        }} />
        </div>
      </div>
            {description.map((item,desIndex)=>(
             <div className='my-1 flex'>
                                 <div className='mt--5 flex ml--5px'>
            <Up     color='#d1d5db'     
              handleClick={() =>{
                if(desIndex !== 0 ){
                const temp = description[desIndex - 1];
                description[desIndex - 1] = description[desIndex];
                description[desIndex] = temp;
                console.log(description)
                projectData = {...projectData, description}
                console.log(projectData)
                savechanges(index,projectData)
                }
        }}/>
             <Down  color='#d1d5db'     
          handleClick={() =>{
            if(desIndex !== (description.length-1) ){
            const temp = description[desIndex + 1];
            description[desIndex + 1] = description[desIndex];
            description[desIndex] = temp;
            console.log(description)
          projectData = {...projectData, description}
          savechanges(index,projectData)
        }}}/>
</div>
              <input
                className={className}
                placeholder='description'
                name='description'
                value={item}
                onChange={(e) => {
                  const nextCounters = description.map((c, i) => {
                    if (i === desIndex) {
                      // Increment the clicked counter
                      return e.target.value;
                    } else {
                      // The dest haven't changed
                      return c;
                    }
                  });
                  description = nextCounters
                  projectData = {...projectData, description}
                  savechanges(index, projectData)
                }}
              />
                            <div className='mt--5'>
          <Remove color='#d1d5db' handleClick={() =>{
          const a = description.slice(0, desIndex).concat(description.slice(desIndex + 1));
          description = a;
          console.log(a)
          projectData = {...projectData, description:a}
          savechanges(index,projectData)
        }} />
      
 
</div> 
              
</div>
            ))}
          </div>
        </>
    
      )}
      </div>
      </div>

  )
}

export default ProjectItem
