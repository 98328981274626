export const Up = ({ handleClick, color }) => (
  <svg
    onClick={handleClick}
    xmlns="http://www.w3.org/2000/svg"
    className='h-6 w-6'
    fill='none'
    viewBox='0 0 24 24'
    stroke={color}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      strokeWidth={2}
      d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
    />
  </svg>
);
