import React from "react";
import { useForm } from "react-hook-form";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { updateUserData } from "../../../../../apiCalls/apiPut.jsx";
import { decodeToken } from "../../../../../apiCalls/userToken.jsx";

const BasicInformation = ({ userData }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  useEffect(() => {
    if (userData) {
      setValue("first_name", userData?.first_name);
      setValue("last_name", userData?.last_name);
      setValue("email", userData?.email);
    }
  }, [userData, setValue]);

  const onSubmit = async (data) => {
    const { userId, role } = await decodeToken();
    if (userId && role === "User") {
      const res = await updateUserData(userId, data);
      // console.log("res,", userId, data);
      if (res.status === "Success") {
        toast("Success updating Profile", {
          render: "Success",
          autoClose: 3000,
          type: "success",
          isLoading: false,
        });
      } else {
        toast({
          render: "Something went wrong",
          autoClose: 3000,
          type: "error",
          isLoading: false,
        });
      }
    } else {
      toast.error("Sign in or You are not a User");
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Basic Information</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row form-row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  readOnly
                  {...register("email", {
                    required: "email is required",
                  })}
                />
                {errors.email && (
                  <span className="text-danger mt-2 d-inline-block">
                    {errors.email?.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  First Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="first_name"
                  {...register("first_name", {
                    required: "First Name is required",
                  })}
                />
                {errors.first_name && (
                  <span className="text-danger mt-2 d-inline-block">
                    {errors.first_name?.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Last Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="last_name"
                  {...register("last_name", {
                    required: "Last Name is required",
                  })}
                />
                {errors.last_name && (
                  <span className="text-danger mt-2 d-inline-block">
                    {errors.last_name?.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6 basicDetailsSubmitBUtton">
              <div className="submit-section submit-btn-up ">
                <button type="submit" className="btn btn-primary submit-btn">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BasicInformation;
