
import  { useState } from 'react'
// import './temp2.css'
// import { renderToString } from 'react-dom/server';

// import { toast } from 'react-toastify';
// import { generatePdf } from '../../../../../apiCalls/apiPost';
// import { renderToString } from 'react-dom/server';


// const onSubmit = async () => {
//   console.log("called")
//   const html = renderToString(<Template2 />);


  
//           const res = await generatePdf(html,1);
//           // console.log("res,", userId, data);
//           if (res.status === "Success") {
//             toast("Success generating pdf", {
//               render: "Success",
//               autoClose: 3000,
//               type: "success",
//               isLoading: false,
//             });
//           } else {
//             toast({
//               render: "Something went wrong",
//               autoClose: 3000,
//               type: "error",
//               isLoading: false,
//             });
//             console.log(res.data)
//           }
//       };
//       onSubmit()

function Template2() {
    const [infoState, setInfoState] = useState({
        Profile:{
            type: "Profile",
            name: "John Doe",
            profession: "Junior Developer",
            profileImageURL: "https://i.imgur.com/f6L6Y57.png",
            display: true,
            email: "nattygirma28@gmail.com",
            phoneNumber: "+1 785 55025 526",
            about:
              "Recent college graduate with experience in various areas of software engineering, including infrastructure and data analytics. A fast learner who thrives on generating innovative ideas, trouble-shooting and problem-solving, and working with object-oriented programming languages including Python and Java. ",
          },
          Other: {
            display:true,
            type: "Other",
          },
    
          Education: {
            header: "Education",
            type: "Education",
            display: true,
            items: [
              {
                degree: "Software Engineering",
                institution: "University of Sydney",
                dateFrom: "Mar 2017",
                dateTo: " Dec 2019",
              },
            ],
          },
          Certifications: {
            header: "Certifications",
            type: "Certifications",
            display: true,
            items: [  
              "Software Engineering",
               "University of Sydney",
             "Mar 2017",
              " Dec 2019",   
            ],
          },
          KeySkills: {
            header: "Key Skills",
            type: "KeySkills",
            display: true,
            text: [
              "C++, HTML5, and JavaScript.",
              "SQL, NoSQL databases",    
              "Extensive Linux/Unix experience",
              "Software testing (Jest, Chai, Mocha)",
              "Node.js",
              "CI/CD Basics dg refr re  erfer  erfdf er f df",
              "C++, HTML5, and JavaScript.",
              "SQL, NoSQL databases",    
              "Extensive Linux/Unix experience",
              "Software testing (Jest, Chai, Mocha)",
              "Node.js",
              "CI/CD Basics",
            ],
          },
          Employment: {
            header: "Employment History",
            type: "Employment",
            display: true,
            items: [
              {
                position: "Software Engineer - Bank of E-Corp",
                dateFrom: "Mar 2017",
                dateTo: "Dec 2019",
                location: "San Fransisco, CA",
                responsibilities: [
                  "Built RESTful API that served data to the JavaScript front-end based on.",
                  "Built international tool using NodeJS and Pupeteer.js to automate QA.",
                  "Conducting code peer reviews with other members in my team\n",
                ],
              },
              {
                position: "Junior Software Developer - Steel Mountain",
                dateFrom: "Mar 2015",
                dateTo: "Dec 2016",
                location: "Atlanta, GA",
                responsibilities: [
                  "Built RESTful API that served data to the JavaScript front-end based on.",
                  "Dynamicially chosen user inputs that handled over 100,000 concurrent users.",
                  "Built international tool using NodeJS and Pupeteer.js to automate QA.",
                  "Conducting code peer reviews with other members in my team\n",
                ],
              },
            ],
          },
          Projects:  {
            header: "Projects",
            type: "Projects",
            display: true,
            items: [
              {
                name: "Poker Simulator",
                description:[
                  "Built a full-stack app to allow users to stimulate and visualize outcomes", 
                  "Contributed of poker hand against opponents",
                  "Utilized ski-kit learn in Python to simulate possible outcomes.",
                  "Used Javascript, Python, SQL, HTML/CSS",
                ]
               },
            ],
          },
    
          Socials:  {
            header: "Socials",
            type: "Socials",
            items: [
              {
                type: "Devto",
                enabled: true,
                url: "",
                viewBox: "0 0 448 512",
                path: "M120.12 208.29c-3.88-2.9-7.77-4.35-11.65-4.35H91.03v104.47h17.45c3.88 0 7.77-1.45 11.65-4.35 3.88-2.9 5.82-7.25 5.82-13.06v-69.65c-.01-5.8-1.96-10.16-5.83-13.06zM404.1 32H43.9C19.7 32 .06 51.59 0 75.8v360.4C.06 460.41 19.7 480 43.9 480h360.2c24.21 0 43.84-19.59 43.9-43.8V75.8c-.06-24.21-19.7-43.8-43.9-43.8zM154.2 291.19c0 18.81-11.61 47.31-48.36 47.25h-46.4V172.98h47.38c35.44 0 47.36 28.46 47.37 47.28l.01 70.93zm100.68-88.66H201.6v38.42h32.57v29.57H201.6v38.41h53.29v29.57h-62.18c-11.16.29-20.44-8.53-20.72-19.69V193.7c-.27-11.15 8.56-20.41 19.71-20.69h63.19l-.01 29.52zm103.64 115.29c-13.2 30.75-36.85 24.63-47.44 0l-38.53-144.8h32.57l29.71 113.72 29.57-113.72h32.58l-38.46 144.8z",
              },
              {
                type: "LinkedIn",
                enabled: true,
                url: "https://linked.in/aaa",
                viewBox: "0 0 448 512",
                path: "M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z",
              },
              {
                type: "Github",
                enabled: true,
                url: "",
                viewBox: "0 0 496 512",
                path: "M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z",
              },
            ],
          },
          Contact:{
            header: "Contact",
            type: "Contact",
            display: true,
            items: [
              {
                text: "+61 01 012121",
              },
              {
                text: "eliot@example.com",
              },
              {
                text: "www.eliotdev.com",
              },
            ],
          },   
      });

      const others = infoState.Other
  const education = infoState.Education
  const skills = infoState.KeySkills
  const profile = infoState.Profile
  const socials= infoState.Socials
  const certifications = infoState.Certifications
  const employment = infoState.Employment
  const projects = infoState.Projects



 
  return (

//     <div className="temp1" >
//       <div className="temp1-inner">

//       <div className="temp1-container">
//         <div className="temp1-header">
//             <h1>{profile?.name}</h1>
//             <p>{profile?.email}</p>
//             <p>{profile?.phoneNumber}</p>
//             <p><a href={socials?.linkedin}>{socials?.linkedin}</a></p>
//         </div>
//         {profile?.display && profile?.about && profile.about !=="" &&
//               <div className="temp1-education">
//               <div className="temp1-section-title"><h3>Summary</h3></div>

//                 <div className='temp1-education-flex'>
//                   <div>
 
//                 <p className="temp1-summary">{profile.about} </p>
           
//                 </div>
                
//                 </div>
       
      
//               </div>
//               }
//         {(education?.display && 
//         <div className="temp1-education">
//         <div className="temp1-section-title"><h3>Education</h3></div>
//         {education?.items.map((item, index) => (
//           <div className='temp1-education-flex'>
//             <div>
//             {(item?.degree && item?.degree !== "" &&
//           <p className="temp1-education-text">{item?.degree} </p>
//             )}
//           {(item?.institution && item?.institution !== "" &&
//           <p className="temp1-education-text">{item?.institution} </p>
//           )}
//           </div>
//           <div className='float-right'>
//           {(item.dateFrom && item.dateTo && item?.dateFrom !== "" && item?.dateTo !== "" &&
//           <p>{`${item.dateFrom} - ${item.dateTo}`}</p>
//           )}
//            {(item.gpa && item?.gpa !== "" &&
//           <p>{`GPA: ${item.gpa}`}</p>
//            )}
//           </div>
          
//           </div>
//   ))}           

//         </div>
//         )}


//         {/* <!-- Work Experience --> */}
//         {(employment?.display && 
//         <div className="temp1-work-experience">
//             <div className="temp1-section-title"><h3>Work Experience</h3></div>
//             {employment?.items.map((item, index) => (
//     <div className="temp1-blocks"   key={index}>
//       {(item?.position && 
//       <span className="temp1-job-title">{item.position}</span>
//       )}
//       {(item?.dateFrom && item?.dateTo && 
//       <span className="temp1-job-duration">{`${item.dateFrom} - ${item.dateTo}`}</span>
//       )}

//         <div>
//           <ul>
         
//           {item?.responsibilities?.map((item, index)=>(
// <li key={index}> {item} </li>
//          ) )}
        
//           </ul>
//           </div>
//       </div>
//         ))}
//         </div>
//         )}
//          {(projects?.display && 
//         <div className="temp1-work-experience">
//             <div className="temp1-section-title"><h3>Projects</h3></div>
//             {projects?.items.map((item, index) => (
//     <div className="temp1-blocks"   key={index}>
//       <span className="temp1-job-title">{item.title}</span>
//       {(item?.dateFrom && 
//       <span className="temp1-job-duration">{`${item?.dateFrom} - ${item?.dateTo}`}</span>
//     )}
//         <div>
//           <ul>
         
//           {item?.description?.map((item, index)=>(
// <li key={index}> {item} </li>
//          ) )}
        
//           </ul>
//           </div>
//       </div>
//         ))}
//         </div>
//          )}
//                   {(certifications?.display && 
//         <div className="temp1-work-experience">
//             <div className="temp1-section-title"><h3>Certifications</h3></div>
//             <ul className="temp1-skills-container">
//   {certifications?.items?.map((item, index)=>(
// <li className="temp1-skills-item" key={index}> {item} </li>
//          ) )}
// </ul>
//         </div>
//          )}

// {others?.display && (
//             <>
//                     <div className="temp1-work-experience">
//             <div className="temp1-section-title"><h3>Work Experience</h3></div>
//             {employment?.items.map((item, index) => (
//     <div className="temp1-blocks"   key={index}>
//       {(item?.position && 
//       <span className="temp1-job-title">{item.position}</span>
//       )}
//       {(item?.dateFrom && item?.dateTo && 
//       <span className="temp1-job-duration">{`${item.dateFrom} - ${item.dateTo}`}</span>
//       )}

//         <div>
//           <ul>
         
//           {item?.responsibilities?.map((item, index)=>(
// <li key={index}> {item} </li>
//          ) )}
        
//           </ul>
//           </div>
//       </div>
//         ))}
//         </div>
//             {others?.items?.map((item, index) => (
//               <div className="temp1-work-experience" key={index}>
//                 <div className="temp1-section-title"><h3>{item?.name}</h3></div>
             
//                     <div className="temp1-blocks">
//                     {(item?.position && 
//       <span className="temp1-job-title">{item.position}</span>
//       )}
//                        {(item?.dateFrom && item?.dateFrom !=='' && item?.dateTo !=='' && 
//       <span className="temp1-job-duration">{`${item?.dateFrom} - ${item?.dateTo}`}</span>
//     )}
//                           <div>
//           <ul>
         
//           {item?.details?.map((i, index)=>(
// <li key={index}> {i} </li>
//          ) )}
        
//           </ul>
//           </div>
//                     </div>
             
           
//               </div>
//             ))}
//             </>
//           )}

// {(skills?.display && 
//         <div className="temp1-work-experience">
//             <div className="temp1-section-title"><h3>Skills</h3></div>

// <ul className="temp1-skills-container">
//   {skills?.text?.map((item, index)=>(
// <li className="temp1-skills-item" key={index}> {item} </li>
//          ) )}
// </ul>

// </div>
//          )}
 

//     </div>
//     </div>
// </div>
<div class="template2">
<div class="template2-inner">
  <div class="temp2-main">
    <header class="temp2-title">
      <h1>{profile?.name}</h1>
      <span class="temp2-subtitle">{profile?.profession}</span>
    </header>
    {/* Summary  */}
    {profile?.display && profile?.about && profile.about !== "" && (
      <section class="temp2-main-block concise temp2-header-container">
        <h2> Summary</h2>

        <section class="temp2-blocks">
          <div class="temp2-date">
            {/* <span>{item?.date?.from}</span><span>{item?.date?.to}</span> */}
          </div>
          <div class="temp2-decorator"></div>
          <div class="temp2-summary-details">
            <div>{profile?.about}</div>
          </div>
        </section>
      </section>
    )}
    {/* Education */}
    {education?.display && (
      <section class="temp2-main-block concise temp2-header-container">
        <h2>
          <i class="fa fa-graduation-cap"></i> Education
        </h2>
        {education?.items.map((item, index) => (
          <section class="temp2-blocks">
            <div class="temp2-date">
              <span>{item?.dateFrom}</span>
              <span>{item?.dateTo}</span>
            </div>
            <div class="temp2-decorator"></div>
            <div class="temp2-details">
              <div class="temp2-header-containers">
                <h3>{item?.degree}</h3>
                <span class="temp2-place">{item?.institution}</span>
                {item?.location && item?.location === "" && (
                  <span class="temp2-location">{item?.location}</span>
                )}
              </div>
              <div>{item?.details}</div>
            </div>
          </section>
        ))}
      </section>
    )}
    {/* Employment */}
    {employment?.display && (
      <section class="temp2-main-block temp2-header-container">
        <h2>
          <i class="temp2-fa temp2-fa-suitcase"></i> Experiences
        </h2>
        {employment?.items.map((item, index) => (
          <section class="temp2-blocks" key={index}>
            <div class="temp2-date">
              <span>{item.dateFrom}</span>
              <span>{item.dateTo}</span>
            </div>
            <div class="temp2-decorator"></div>
            <div class="temp2-details">
              <div class="temp2-header-containers">
                <h3>{item.position}</h3>
                <span class="temp2-place">{item.location}</span>
                {item?.location && item?.location === "" && (
                  <span class="temp2-location">{item?.location}</span>
                )}
              </div>

              <ul>
                {item?.responsibilities?.map((item, index) => (
                  <li key={index}> {item} </li>
                ))}
              </ul>
            </div>
          </section>
        ))}
      </section>
    )}
    {/* Projects  */}
    {projects?.display && (
      <section class="temp2-main-block temp2-header-container">
        <h2>
          <i class="temp2-fa fa-folder-open"></i> Projects
        </h2>
        {projects?.items.map((item, index) => (
          <section class="temp2-blocks" key={index}>
            <div class="temp2-date">
              <span>{item.dateFrom}</span>
              <span>{item.dateTo}</span>
            </div>
            <div class="temp2-decorator"></div>
            <div class="temp2-details">
              <div class="temp2-header-containers">
                <h3>{item.title}</h3>
                <span class="temp2-place">{item.location}</span>
                {item?.location && item?.location === "" && (
                  <span class="temp2-location">{item?.location}</span>
                )}
              </div>

              <ul>
                {item?.description?.map((item, index) => (
                  <li key={index}> {item} </li>
                ))}
              </ul>
            </div>
          </section>
        ))}
      </section>
    )}
    {/* Others  */}
    {others?.items?.map((item, index) => (
      <div>
        {item?.display && (
          <section class="temp2-main-block temp2-header-container">
            <h2>
              <i class="temp2-fa fa-folder-open"></i> {item?.name}
            </h2>

            <section class="temp2-blocks" key={index}>
              <div class="temp2-date">
                {item.dateFrom &&
                  item.dateFrom &&
                  item.dateFrom !== "" &&
                  item.dateFrom !== "" && (
                    <>
                      <span>{item.dateFrom}</span>
                      <span>{item.dateTo}</span>
                    </>
                  )}
              </div>
              <div class="temp2-decorator"></div>
              <div class="temp2-details">
                <div class="temp2-header-containers">
                  {item.position && <h3>{item.position}</h3>}

                  {item?.location && item?.location === "" && (
                    <span class="temp2-location">{item?.location}</span>
                  )}
                </div>

                <ul>
                  {item?.details?.map((item, index) => (
                    <li key={index}> {item} </li>
                  ))}
                </ul>
              </div>
            </section>
          </section>
        )}
      </div>
    ))}
  </div>
  <aside class="temp2-sidebar">
    {profile?.display && (
      <div class="temp2-side-block temp2-contact">
        <h1>Contact Info</h1>
        <ul>
          {/* <li><i class="fa fa-globe"></i>{socials.items}</li> */}
          <li>
            <i class="fa fa-envelope"></i>
            {profile?.email}
          </li>
          <li>
            <i class="fa fa-phone"></i> {profile?.phoneNumber}
          </li>
          {socials?.items?.map((a, i) => {
            if (a?.enabled) return <li>{a.url}</li>;
            return null;
          })}
        </ul>
      </div>
    )}
    {skills?.display && (
      <div class="temp2-side-block temp2-skills">
        <h1>Skills</h1>
        <ul>
          {skills?.text?.map((item, index) => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
    )}
    {certifications?.display && (
      <div class="temp2-side-block temp2-skills">
        <h1>Certifications</h1>
        <ul>
          {certifications?.items?.map((item, index) => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
    )}
  </aside>
</div>
</div>
  )
}

export default Template2