import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function UpgradeCard({userData}) {
  const navigate= useNavigate();
  function userLogout(){
    localStorage.removeItem('token');
   navigate("/login")
  }
  const [trial,setTrial] = useState(10);
  useEffect(() => {
    setTrial((10-userData?.freeTrial) || 10)
  }, [userData])
  
  return (
    <div className="border border-light rounded-3">
      <div className="px-3 py-2 bg-white border-bottom border-light rounded-top-3">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <h6 className="lead-text">Free Plan</h6>
          {/* <a className="link link-primary" href="/pricing-plans">
            <em className="ni ni-spark-fill icon text-warning" />
            <span>Upgrade</span>
          </a> */}
        </div>
        <div className="progress progress-md">
          <div
            className="progress-bar"
            data-progress="25%"
            style={{ width: '25%' }}
          />
        </div>
        <h6 className="lead-text mt-2">
          Unlimited <span className="text-light">Resumes left</span>
        </h6>
        <h6 className="lead-text ">
          {trial}/10 <span className="text-light">AI Generation left</span>
        </h6>
      </div>
      <Link
        className="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3"
        href="/profile"
      >
        <div className="media-group">
          <div className="media media-sm media-middle media-circle text-bg-primary">
            <img 
          src={userData?.profilePicture || "images/avatar/a.png" }
            alt="Profile"
            referrerpolicy='no-referrer'
            />
          </div>
          <div className="media-text">
            <h6 className="fs-6 mb-0">{userData?.first_name} {userData?.last_name}</h6>
            
            <span className="text-light fs-7">{userData?.email}</span>
          </div>
          <em className="icon ni ni-chevron-right ms-auto ps-1" />
        </div>
      </Link>
      <button className='btn-logout' onClick={userLogout}>
        Log Out
      </button>
    </div>
  );
}
