import { useState, useContext } from "react";
import { BuilderContext } from "../index";
import EmploymentItem from "./Editor/EmploymentItem";
import ActionMenu from "./Editor/ActionMenu";

import { Remove } from "./Editor/Icons/Remove";
import { Add } from "./Editor/Icons/Add";
import ProjectItem from "./Editor/ProjectItem";
import ToggleButton from './Editor/ToggleButton'
import { Up } from "./Editor/Icons/Up";
import { Down } from "./Editor/Icons/Down";

const ProfessionalDetailsResume = ({a}) => {
  const ctx = useContext(BuilderContext);
  const [employmentInfo, setEmploymentInfo] = useState(a?.Employment);
  let skillsData = a?.KeySkills;
  let skills = a?.KeySkills?.text;
  let certificationsData = a?.Certifications;
  let certifications = a?.Certifications?.items;

  const [projects, setProjects] = useState(a?.Projects);

  const newItem = {
    position: "Software Engineer - Google",
    dateFrom: "Mar 2017",
    dateTo: "Dec 2019",
    location: "Atlanta, GA",
    responsibilities: ["Some cool stuff"],
  };
  const newProject = {
    title: "Resume maker",
    dateFrom: "Mar 2017",
    dateTo: "Dec 2019",
    location: "Atlanta, GA",
    description: ["Some cool stuff"],
  };
  const handleChange = (type, property, value) => {
    if(type==="Certification"){
  certificationsData = { ...certificationsData, display: value }
    ctx.updateInfo({ ...certificationsData, display: value })
    }else if(type==="Employment"){
      setEmploymentInfo ({ ...employmentInfo, display: value })
      ctx.updateInfo({ ...employmentInfo, display: value })
    }
    else if(type==="Skill"){
      skillsData =  { ...skillsData, display: value }
      ctx.updateInfo({ ...skillsData, display: value })
    }
    else if(type==="Project"){
      setProjects ({ ...projects, display: value })
      ctx.updateInfo({ ...projects, display: value })
    }

  }
  const savechanges = (index, changes) => {
    const newMembers = employmentInfo.items.map((c, i) => {
      if (i === index) {
        return changes;
      } else {
        return c;
      }
    });
    setEmploymentInfo({ ...employmentInfo, items: newMembers });
    ctx.updateInfo({ ...employmentInfo, items: newMembers });
  };
  const saveProjectchanges = (index, changes) => {
    const newMembers = projects.items.map((c, i) => {
      if (i === index) {
        return changes;
      } else {
        return c;
      }
    });
    setProjects({ ...projects, items: newMembers });
    ctx.updateInfo({ ...projects, items: newMembers });
  };

  return (
    <div className="tab-pane fade show active">
      <form className="px-4 py-4">
        <div className="row gy-4 gx-4 form-separator">
          <div className="col-12">
            <div className="flex justify-items">
              <div className='flex justify-next'>
              <label className="form-label">Employment History</label>

            <ToggleButton
                className='mgl-2'
                name="Employment"
                handleChange={handleChange}
                defaultValue={true}
              />
              </div>
              <ActionMenu
                btnHide={true}
                className="mt--10px"
                handleAddClick={() => {
                  setEmploymentInfo({
                    ...employmentInfo,
                    items: [...employmentInfo.items, newItem],
                  });
                  ctx.updateInfo({
                    ...employmentInfo,
                    items: [...employmentInfo.items, newItem],
                  });
                }}
                handleRemoveClick={() => {
                  const a = employmentInfo.items.filter(
                    (item, index) => index < employmentInfo.items.length - 1
                  );
                  setEmploymentInfo({
                    ...employmentInfo,
                    items: employmentInfo.items.filter(
                      (item, index) => index < employmentInfo.items.length - 1
                    ),
                  });
                  ctx.updateInfo({
                    ...employmentInfo,
                    items: a,
                  });
                }}
              />
            </div>
            {employmentInfo?.items?.map((item, index) => (
              <EmploymentItem
                className="form-control"
                key={index}
                index={index}
                data={item}
                savechanges={savechanges}
              />
            ))}
          </div>
          <div className="col-12">
            <div className="flex justify-items">
              <div className='flex justify-next'>
              <label className="form-label">Projects</label>

            <ToggleButton
                className='mgl-2'
                name="Project"
                handleChange={handleChange}
                defaultValue={true}
              />
              </div>
              <ActionMenu
                btnHide={true}
                className="mt--10px"
                handleAddClick={() => {
                  setProjects({
                    ...projects,
                    items: [...projects.items, newProject],
                  });
                  ctx.updateInfo({
                    ...projects,
                    items: [...projects.items, newProject],
                  });
                }}
                handleRemoveClick={() => {
                  const a = projects.items.filter(
                    (item, index) => index < projects.items.length - 1
                  );
                  setProjects({
                    ...projects,
                    items: projects.items.filter(
                      (item, index) => index < projects.items.length - 1
                    ),
                  });
                  ctx.updateInfo({
                    ...projects,
                    items: a,
                  });
                }}
              />
            </div>
            {projects?.items?.map((item, index) => (
              <ProjectItem
                className="form-control"
                key={index}
                index={index}
                data={item}
                savechanges={saveProjectchanges}
              />
            ))}
          </div>
        </div>
        <div className="row gy-4 gx-4 form-separator">
          <div className="col-12">
            <div className="form-group">
              <div className="flex justify-items pt-10px ml-20px ">
                <div className='flex justify-next'>
                <label htmlFor="PrimaryKeywords" className="form-label">
                  Skills
                </label>
            <ToggleButton
                className='mgl-2'
                name="Skill"
                handleChange={handleChange}
                defaultValue={true}
              />
              </div>
                <div className="flex">
                  <Add
                    color="#d1d5db"
                    handleClick={() => {
                      skills = [...skills, "New skills"];
                      skillsData = { ...skillsData, text: skills };
                      ctx.updateInfo(skillsData);
                    }}
                  />

                  <Remove
                    color="#d1d5db"
                    handleClick={() => {
                      skills = skills.filter((a, i) => i !== skills.length - 1);
                      skillsData = { ...skillsData, text: skills };
                      ctx.updateInfo(skillsData);
                    }}
                  />
                </div>
              </div>
              <div className="form-control-wrap">
                {skills.map((skill, index) => (
                    <div className='my-1 flex'>
                                            <div className='mt--5 flex'>
                                            <Up     color='#d1d5db'     
                                              handleClick={() =>{
                                                if(index !== 0 ){
                                                const temp = skills[index - 1];
                                                skills[index - 1] = skills[index];
                                                skills[index] = temp;
                                  
                                                skillsData = {...skillsData, skills}
                                
                                                ctx.updateInfo(skillsData);
                                                }
                                        }}/>
                                             <Down  color='#d1d5db'     
                                          handleClick={() =>{
                                            if(index !== (skills.length-1) ){
                                            const temp = skills[index + 1];
                                            skills[index + 1] = skills[index];
                                            skills[index] = temp;
                                         
                                          skillsData = {...skillsData, skills}
                                          ctx.updateInfo(skillsData);
                                        }}}/>
                                        </div>
                             
                  <input
                    type="text"
                    key={index}
                    className="form-control"
                    placeholder="Key Skills"
                    value={skill}
                    onChange={(e) => {
                      const nextCounters = skills.map((c, i) => {
                        if (i === index) {
                          // Increment the clicked counter
                          return e.target.value;
                        } else {
                          // The rest haven't changed
                          return c;
                        }
                      });
                      skills = nextCounters;
                      skillsData = { ...skillsData, text: skills };
                      ctx.updateInfo(skillsData);
                    }}
                  />
                  <div className='mt--5'>
                  <Remove color='#d1d5db' handleClick={() =>{
                  const a = skills.slice(0, index).concat(skills.slice(index + 1));
                  skills = a;
           
                  skillsData = {...skillsData, text:a}
                  ctx.updateInfo(skillsData);
                }} />
                </div>
</div> 
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-items pt-10px ml-20px ">
            <div className='flex justify-next'>
            <label htmlFor="PrimaryKeywords" className="form-label">
              Certifications
            </label>

            <ToggleButton
                className='mgl-2'
                name="Certification"
                handleChange={handleChange}
                defaultValue={true}
              />
              </div>
            <div className="flex">
              <Add
                color="#d1d5db"
                handleClick={() => {
                  certifications = [...certifications, "New certifications"];
                  certificationsData = {
                    ...certificationsData,
                    items: certifications,
                  };
                  ctx.updateInfo(certificationsData);
                }}
              />

              <Remove
                color="#d1d5db"
                handleClick={() => {
                  certifications = certifications.filter(
                    (a, i) => i !== certifications.length - 1
                  );
                  certificationsData = {
                    ...certificationsData,
                    items:certifications,
                  };
                  ctx.updateInfo(certificationsData);
                }}
              />
            </div>
          </div>
          <div className="form-control-wrap">
            {certifications?.map((c, index) => (
                <div className='my-1 flex'>
              <input
                type="text"
                key={index}
                className="form-control"
                placeholder="Certifications"
                value={c}
                onChange={(e) => {
                  const nextCounters = certifications?.map((c, i) => {
                    if (i === index) {
                      // Increment the clicked counter
                      return e.target.value;
                    } else {
                      // The rest haven't changed
                      return c;
                    }
                  });
                  certifications = nextCounters;
                  certificationsData = {
                    ...certificationsData,
                    items: certifications,
                  };
               
                  ctx.updateInfo(certificationsData);
                }}
              />
               <div className='mt--5'>
              <Remove
                color="#d1d5db"
                handleClick={() => {
                  const a = certifications.slice(0, index).concat(certifications.slice(index + 1));
                  certifications = a;
          
                  certificationsData = {...certificationsData, items:a}
                  ctx.updateInfo(certificationsData);
                }}
              />
              </div>
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfessionalDetailsResume;
