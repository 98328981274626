import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import './client/components/css/style.css';
import './client/components/css/table.css'
import './client/components/css/custom.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

const googlClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={googlClientId}>
    <App />
  </GoogleOAuthProvider>
);
