import React, { useState, useEffect, createContext } from "react";
import {useSearchParams } from "react-router-dom";
import SideNav from "../../../ui/SideNav";
import Footer from "../../../ui/Footer";
import { decodeToken } from "../../../../../apiCalls/userToken";
import { getResume, getUsersData } from "../../../../../apiCalls/apiFetch";
import ResumeEditor from "./resume-editor";
import PreviewScreen from "./resume-templates";

export const BuilderContext = createContext({});

function CreateResumeDashboard() {
  let [searchParams] = useSearchParams();
  const [userData, setUserData] = useState([]);
  const id = searchParams.get("id");

  const [a, setA] = useState({});

  let template = searchParams.get("template") || 2;
  template = Number(template)
  useEffect(() => {
    const fetch = async () => {
      const { userId, role } = await decodeToken();
      if (userId && role === "User" && id) {
        let data = await getResume(id);
        if (data) {
          setA(data);
        } else {
          
        }
      } else {
        const infoState = {
          Profile: {
            type: "Profile",
            name: "John Doe",
            profession: "Junior Developer",
            profileImageURL: "https://i.imgur.com/f6L6Y57.png",
            display: true,
            email: "nattygirma28@gmail.com",
            phoneNumber: "+1 785 55025 526",
            about:
              "Recent college graduate with experience in various areas of software engineering, including infrastructure and data analytics. A fast learner who thrives on generating innovative ideas, trouble-shooting and problem-solving, and working with object-oriented programming languages including Python and Java. ",
              
          },
          Other: {
            display:true,
            type: "Other",
          },
          

          Education: {
            header: "Education",
            type: "Education",
            display: true,
            items: [
              {
                degree: "Software Engineering",
                institution: "University of Sydney",
                location: "Sydney, Astralia",
                dateFrom: "Mar 2017",
                dateTo: " Dec 2019",
              },
            ],
          },
          KeySkills: {
            header: "Key Skills",
            type: "KeySkills",
            display: true,
            text: [
              "C++, HTML5, and JavaScript.",
              "SQL, NoSQL databases",
              "Node.js",
              "Extensive Linux/Unix experience",
              "Software testing (Jest, Chai, Mocha)",
              "CI/CD Basics",
            ],
          },
          Employment: {
            header: "Employment History",
            type: "Employment",
            display: true,
            items: [
              {
                position: "Software Engineer - Bank of E-Corp",
                dateFrom: "Mar 2017",
                dateTo: "Dec 2019",
                location: "San Fransisco, CA",
                responsibilities: [
                  "Built RESTful API that served data to the JavaScript front-end based on.",
                  "Built international tool using NodeJS and Pupeteer.js to automate QA.",
                  "Conducting code peer reviews with other members in my team\n",
                ],
              },
              {
                position: "Junior Software Developer - Steel Mountain",
                dateFrom: "Mar 2015",
                dateTo: "Dec 2016",
                location: "Atlanta, GA",
                responsibilities: [
                  "Built RESTful API that served data to the JavaScript front-end based on.",
                  "Dynamicially chosen user inputs that handled over 100,000 concurrent users.",
                  "Built international tool using NodeJS and Pupeteer.js to automate QA.",
                  "Conducting code peer reviews with other members in my team\n",
                ],
              },
            ],
          },

          skills: {
            header: "Skills",
            type: "Skills",
            display: true,
            items: [
              {
                text: "Python",
                level: "25%",
              },
            ],
          },
          Certifications: {
            header: "Certifications",
            type: "Certifications",
            display: true,
            items: [
              "Oracle Certified Associate (OCA)",
              "AWS Cloud Practitoner",
            ],
          },
          Socials: {
            header: "Socials",
            type: "Socials",
            items: [
              {
                type: "Devto",
                enabled: true,
                url: "",
              },
              {
                type: "LinkedIn",
                enabled: true,
                url: "https://linked.in/aaa",
              },
              {
                type: "Github",
                enabled: true,
              },
            ],
          },
          Projects: {
            header: "Projects",
            type: "Projects",
            display: true,
            items: [
              {
                title: "Poker Simulator",
                dateFrom: "Mar 2017",
                dateTo: "Dec 2019",
                location: "San Fransisco, CA",
                description: [
                  "Built a full-stack app to allow users to stimulate and visualize outcomes",
                  "Contributed of poker hand against opponents",
                  "Utilized ski-kit learn in Python to simulate possible outcomes.",
                  "Used Javascript, Python, SQL, HTML/CSS",
                ],
              },
            ],
          },
        };
        setA(infoState);
      }
    };
    fetch();
  }, [id]);

  const getData = () => {
    const data = a;
    return data;
  };

  const updateInfo = (item) => {
    const b = { ...a, [item.type]: item };

    setA(b);
  };

  useEffect(() => {
    const fetch = async () => {
      const { userId, role, profilePicture } = await decodeToken();
      if (userId && role === "User") {
        const data = await getUsersData(userId);
        if (data) setUserData({ ...data, profilePicture });
      }
    };
    fetch();
  }, []);

  return (
    <div className="nk-app-root" data-sidebar-collapse="lg">
      <div className="nk-main">
        <SideNav userData={userData} />
        <div className="nk-wrap">
          <div className="nk-content">
            <div className="container-xl">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-editor-main">
                    <BuilderContext.Provider
                      value={{
                        updateInfo,
                        getData,
                      }}
                    >
                      <ResumeEditor
                      template={template}
                        a={a}
                        userData={userData}
                        generateContent={true}
                      />
                      <div className="nk-editor-body resume-container">
                        <PreviewScreen template={template} a={a} />
                      </div>
                    </BuilderContext.Provider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default CreateResumeDashboard;
