import { Button, Col, Modal, Row } from 'react-bootstrap';
import Icon from '../Icon';
import { useState } from 'react';

export default function AIGenerateResumeModal({setShow,show, generateResume }) {
  const [description, setDescription] = useState("");
  const handleClose = () => setShow(false);
  return (
    <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="p-4">
            <div className="media media-rg media-middle media-circle text-primary bg-primary bg-opacity-20 mb-3">
              <Icon icon="spark-fill" className="icon" />
            </div>
            <h2 className="h1">Enter Job description</h2>
            <p>
              You can Either choose to enter job description or any prompt you want.
               {/* <span className="text-dark">23 Mar, 2023</span>. */}
            </p>
            <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
              <div className="card-body">
              <div className='col-12'>

        <textarea
        className="form-control"
        placeholder='Job description'
        onChange={(e) => {
          setDescription(e.target.value)
      }}
  
      />
      </div>
              </div>
            </div>

            <Row className="gx-4 mt-4 gap-sm-10">
              <Col sm="6">
                <Button variant="outline-light w-100" onClick={() => {
                  // setShow(false)
                  generateResume(description)
                }}>
                  Generate
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
