import { Add } from './Icons/Add'
import { Remove } from './Icons/Remove'
const ActionMenu = ({
  className,
  btnHide,
  style,
  handleSaveClick,
  handleAddClick,
  handleRemoveClick,
}) => {
  return (
    <div className={`flex py-2 flex-row justify-between ${style} ${className} `}>
      {btnHide? <></>:
      <button
        className='btn btn-primary'
        onClick={handleSaveClick}
      >
        Save
      </button>
       }
      <div className='flex flex-row pt-10px ml-20px'>
        <Add color='#d1d5db' handleClick={handleAddClick} />

        <Remove color='#d1d5db' handleClick={handleRemoveClick} />
      </div>
    </div>
  )
}

export default ActionMenu
