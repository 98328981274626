import { useState } from 'react';
import PricingCard from '../../../ui/PricingCard';
import {Link} from 'react-router-dom';
import SectionHeading from '../../../ui/SectionHeading';

export default function PricingPlansPage({userData}) {
  const [activePlan, setActivePlan] = useState(1);
  const handlePlan = tabNumber => {
    setActivePlan(tabNumber);
  };

  console.log(userData)

  return (
    <div className="nk-content-body">
      <SectionHeading
        title="Pricing Plans"
        subTitle="Generate unlimited copy 10X faster with our cost effective plan to write blog posts, social media ads and many more."
      />
      <div className="nk-block">
        <div className="pricing-toggle-wrap mb-4">
          <button
            className={`pricing-toggle-button ${
              activePlan === 1 ? 'active' : ''
            }`}
            onClick={() => handlePlan(1)}
          >
            Monthly
          </button>
          <button
            className={`pricing-toggle-button ${
              activePlan === 2 ? 'active' : ''
            }`}
            onClick={() => handlePlan(2)}
          >
            Yearly (Save 25%)
          </button>
        </div>
        <div className="card mt-xl-5">
          {activePlan === 1 && (
            <div className="row g-0">
              <div className="col-xl-4">
                <PricingCard
                  email={userData?.email}
                  customer={userData?.stripe_customer_id}
                  packageName="Basic"
                  tilte="Customized Plan"
                  price="Free"
                  btnText="Upgrade Now"
                  btnNote="Cancel Anytime"
                  featureList={[
                    '1000 words/mo. generation',
                    'Total 5000 words generation',
                    'Access to 10 templates',
                    'Upto 5 projects can be created',
                    'Only 7 days of generation history',
                  ]}
                />
              </div>
              <div className="col-xl-4">
                <PricingCard
                email={userData?.email}
                customer={userData?.stripe_customer_id}
                  packageName="Professional"
                  tilte="Customized Plan"
                  plan="basic_m"
                  price="$15.99"
                  duration="/ month"
                  btnText="Upgrade Now"
                  btnNote="Cancel Anytime"
                  featureList={[
                    '1000 words/mo. generation',
                    'Total 5000 words generation',
                    'Access to 10 templates',
                    'Upto 5 projects can be created',
                    'Only 7 days of generation history',
                  ]}
                  featured={true}
                />
              </div>
              <div className="col-xl-4">
                <PricingCard
                email={userData?.email}
                customer={userData?.stripe_customer_id}
                  packageName="Enterprise"
                  tilte="Customized Plan"
                  plan="golden_m"
                  price="$45.5"
                  duration="/ month"
                  btnText="Upgrade Now"
                  btnNote="Cancel Anytime"
                  featureList={[
                    'Dedicated Account Manager',
                    'Custom Tools',
                    'Advanced Integrations',
                    'Premium Technical Support',
                    'Many More Special Features',
                  ]}
                />
              </div>
            </div>
          )}
          {activePlan === 2 && (
            <div className="row g-0">
              <div className="col-xl-4">
                <PricingCard
                email={userData?.email}
                customer={userData?.stripe_customer_id}
                  packageName="Basic"
                  tilte="Customized Plan"
                  price="Free"
                  btnText="Upgrade Now"
                  btnNote="Cancel Anytime"
                  featureList={[
                    '1000 words/mo. generation',
                    'Total 5000 words generation',
                    'Access to 10 templates',
                    'Upto 5 projects can be created',
                    'Only 7 days of generation history',
                  ]}
                />
              </div>
              <div className="col-xl-4">
                <PricingCard
                email={userData?.email}
                customer={userData?.stripe_customer_id}
                  packageName="Professional"
                  tilte="Customized Plan"
                  price="$150"
                  plan="basic_y"
                  duration="/ year"
                  btnText="Upgrade Now"
                  btnNote="Cancel Anytime"
                  featureList={[
                    '1000 words/mo. generation',
                    'Total 5000 words generation',
                    'Access to 10 templates',
                    'Upto 5 projects can be created',
                    'Only 7 days of generation history',
                  ]}
                  featured={true}
                />
              </div>
              <div className="col-xl-4">
                <PricingCard
                email={userData?.email}
                customer={userData?.stripe_customer_id}
                  packageName="Enterprise"
                  tilte="Customized Plan"
                  price="$450"
                  plan="golden_y"
                  duration="/ year"
                  btnText="Upgrade Now"
                  btnNote="Cancel Anytime"
                  featureList={[
                    'Dedicated Account Manager',
                    'Custom Tools',
                    'Advanced Integrations',
                    'Premium Technical Support',
                    'Many More Special Features',
                  ]}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt-5">
          <h5>Want to learn more about our pricing &amp; plans?</h5>
          <p>
            Read our <Link href="/pricing-plans">Plans</Link>,{' '}
            <Link href="/pricing-plans">Billing &amp; Payment</Link>,{' '}
            <Link href="/pricing-plans">FAQs</Link> to learn more about our
            service.
          </p>
        </div>
      </div>
      {/* .nk-block */}
    </div>
  );
}
