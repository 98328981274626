import React, { useEffect, useState } from "react";
import { getusersCampaigns } from "../../../../../../apiCalls/apiFetch";
import { toast } from "react-toastify";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { decodeToken } from "../../../../../../apiCalls/userToken";
import Icon from "../../../../ui/Icon";
import { userEditCampaign } from "../../../../../../apiCalls/apiPut.jsx";

function DashBoardCampaigns({ UserData }) {
  const [loadingUsersCampaigns, setLoadingUsersCampaigns] = useState(false);
  const [CampaignToEdit, setCampaignToEdit] = useState(null);
  const [UsersCampaigns, setUsersCampaigns] = useState([]);
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (UserData?.campaigns) {
      const fetch = async () => {
        setLoadingUsersCampaigns(true);
        const campaigns = await getusersCampaigns({
          userId: UserData?._id,
          type: "Twitter",
        });
        if (campaigns[0] !== null) setUsersCampaigns(campaigns);
        setLoadingUsersCampaigns(false);
      };
      fetch();
    }
  }, [UserData]);

  const openModal = async (campaign) => {
    setCampaignToEdit(campaign);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const editCampaign = async (data) => {
    const { userId, role } = await decodeToken();
    if (userId && role === "User") {
      const res = await userEditCampaign({
        id: CampaignToEdit?._id,
        dataUpdate: data,
      });
      // console.log("res,", userId, data);
      if (res.status === "Success") {
        toast("Success updating Campaign", {
          render: "Success",
          autoClose: 3000,
          type: "success",
          isLoading: false,
        });
        window.location.reload();
      } else {
        toast({
          render: "Something went wrong",
          autoClose: 3000,
          type: "error",
          isLoading: false,
        });
      }
    } else {
      toast.error("Sign in or You are not a User");
    }
  };

  useEffect(() => {
    if (CampaignToEdit) {
      setValue("title", CampaignToEdit.title);
      setValue("schedule", CampaignToEdit.schedule);
      setValue("prompt", CampaignToEdit.prompt);
      setValue("status", CampaignToEdit.status);
    }
  }, [CampaignToEdit, setValue]);

  return (
    <>
      <div className="nk-block">
        <h4 className="mb-4">Twitter Campaign</h4>
        <div className=" table-responsive">
          <table className="table table-middle mb-0 table-nowrap">
            <thead className="table-light">
              <tr>
                <th className="tb-col">
                  <h6 className="overline-title">Name</h6>
                </th>
                <th className="tb-col">
                  <h6 className="overline-title">Schedule /day</h6>
                </th>
                <th className="tb-col">
                  <h6 className="overline-title">Status</h6>
                </th>
                <th className="tb-col">
                  <h6 className="overline-title">Last Modified</h6>
                </th>
                <th className="tb-col" />
              </tr>
            </thead>
            <tbody>
              {loadingUsersCampaigns ? (
                <>Loading</>
              ) : (
                <>
                  {UsersCampaigns?.map((campaign) => {
                    const dateCreated = new Date(campaign?.createdAt);
                    const options = {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    };
                    const formattedDate = dateCreated.toLocaleDateString(
                      undefined,
                      options
                    );
                    const formattedTime = dateCreated.toLocaleTimeString();
                    let checked = false;
                    if (campaign.status === "Active") {
                      checked = true;
                    }
                    return (
                      <tr key={campaign?._id}>
                        <td className="tb-col">
                          <div className="caption-text">{campaign.title}</div>
                        </td>
                        <td className="tb-col">
                          <div className="caption-text">{campaign.schedule}</div>
                        </td>
                        <td className="tb-col ">
                          <div
                          // className={`badge text-bg-${campaign.documentTypeClass}-soft rounded-pill px-2 py-1 fs-6 lh-sm`}
                          >
                            <label class="switch">
                              <input
                                type="checkbox"
                                checked={checked}
                                onChange={() => {
                                  openModal(campaign);
                                }}
                              />
                              <span class="slider"></span>
                            </label>
                          </div>
                        </td>
                        <td className="tb-col ">
                          <div className="fs-6 text-light gap gx-2">
                            <span>{formattedDate} </span>
                            <br /> <span>{formattedTime}</span>
                          </div>
                        </td>
                        <td className="tb-col tb-col-end">
                          <Dropdown>
                            <Dropdown.Toggle variant="custom-icon btn-sm btn-icon btn-zoom me-n1">
                              <em className="icon ni ni-edit" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-c-position z-axis">
                              <div className="dropdown-content">
                                <Dropdown.Item>
                                  <button
                                    className="btn-as-a-link link-list-hover-bg-primary link-list-md justify"
                                    onClick={() => openModal(campaign)}
                                  >
                                    <Icon icon="edit" className="icon" />
                                    Edit
                                  </button>
                                </Dropdown.Item>

                                <Dropdown.Item>
                                  <button
                                    className="btn-as-a-link link-list-hover-bg-primary link-list-md justify"
                                    onClick={() => openModal(campaign)}
                                  >
                                    <Icon icon="trash" className="icon" />
                                    Remove
                                  </button>
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="p-4">
            <h2 className="h1">Edit campaign</h2>

            <Row className="row gy-4 gx-4">
              <form onSubmit={handleSubmit(editCampaign)}>
                <div className="row form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        className="select form-control"
                        {...register("status", {
                          required: "Status Required",
                        })}
                      >
                        <option>Active</option>
                        <option>Sleep</option>
                        <option>Delete</option>
                      </select>
                      {errors.status && (
                        <span className="text-danger mt-2 d-inline-block">
                          {errors.status?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="title"
                        className="form-control"
                        {...register("title", {
                          required: "Title is required",
                        })}
                      />
                      {errors.title && (
                        <span className="text-danger mt-2 d-inline-block">
                          {errors.title?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Schedule <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="schedule"
                        {...register("schedule", {
                          required: "Schedule is required",
                        })}
                      />
                      {errors.schedule && (
                        <span className="text-danger mt-2 d-inline-block">
                          {errors.schedule?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Prompt <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="prompt"
                        {...register("prompt", {
                          required: "prompt is required",
                        })}
                      />
                      {errors.prompt && (
                        <span className="text-danger mt-2 d-inline-block">
                          {errors.prompt?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <Row className="gx-4 mt-4">
                    <Col sm="6">
                      <Button
                        variant="outline-light w-100"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </Col>
                    <Col sm="6">
                      <Button type="submit" variant="primary w-100">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </div>
              </form>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DashBoardCampaigns;
