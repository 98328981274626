import React from "react";
import BasicInformation from "./basicInformation";
import ContactDetailsProfileSetting from "./contactDetails";

const UserDashBoardProfileSetting = ({ userData }) => {
  return (
    <div className="col-md-7 col-lg-8 col-xl-9">
      <BasicInformation userData={userData} />
      <ContactDetailsProfileSetting userData={userData} />
    </div>
  );
};

export default UserDashBoardProfileSetting;
