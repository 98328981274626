import axios from "axios";

export async function registerUser(credentials) {
  try {
    const { data } = await axios.post(
      `/api/authUsers/registerUser`,
      credentials
    );
    return data;
  } catch (error) {
    return { error };
  }
}
export async function registerReviewer(credentials) {
  try {
    const { data } = await axios.post(
      `/api/authReviewers/registerReviewer`,
      credentials
    );
    return data;
  } catch (error) {
    return { error };
  }
}
export async function checkEmailUser(credentials) {
  try {
    const { data } = await axios.post(
      `/api/authUsers/checkEmail`,
      credentials
    );

    return { data };
  } catch (error) {
    return { error };
  }
}
export async function checkEmailReviewer(email) {
  try {
    const { data } = await axios.post(`/api/authReviewers/checkEmail`, email);

    return { data };
  } catch (error) {
    return { error };
  }
}

/** generate OTP */
export async function generateOTPReviewer(email) {
  try {
    const { data } = await axios.get("/api/authReviewers/generateOTP", {
      params: { email },
    });

    // send mail with the OTP
    if (data.status === "Success") {
      let text = `Your Account Activation OTP is ${data.code}. Verify and recover your password.`;
      await axios.post("/api/authReviewers/registerMail", {
        username: "Dear Editor",
        userEmail: email,
        text,
        subject: "Password Recovery OTP",
      });
    }
    return data;
  } catch (error) {
    return Promise.reject({ error });
  }
}
export async function generateOTPUser(email) {
  try {
    const { data } = await axios.get("/api/authUsers/generateOTP", {
      params: { email },
    });

    // send mail with the OTP
    console.log(data);
    if (data.status === "Success") {
      let text = `Your Account Activation OTP is ${data.code}. Verify and setup your password.`;
      const d = await axios.post("/api/authUsers/registerMail", {
        username: "Dear User",
        userEmail: email,
        text,
        subject: "Account SignUp OTP",
      });
      console.log("D", d);
    }
    return data;
  } catch (error) {
    return Promise.reject({ error });
  }
}

/** verify OTP */
export async function verifyOTPReviewer({ email, code }) {
  try {
    const data = await axios.get("/api/authReviewers/verifyOTP", {
      params: { email, code },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function verifyOTPUser({ email, code }) {
  try {
    const data = await axios.get("/api/authUsers/verifyOTP", {
      params: { email, code },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function checkUserUserName(credentials) {
  try {
    const { data } = await axios.post(
      `/api/authUsers/checkUserName`,
      credentials
    );

    return data;
  } catch (error) {
    return { error };
  }
}
export async function checkReviewerUserName(credentials) {
  try {
    const { data } = await axios.post(
      `/api/authReviewers/checkUserName`,
      credentials
    );

    return data;
  } catch (error) {
    return { error };
  }
}
export async function loginUser({ email, password }) {
  try {
    if (email) {
      const { data } = await axios.post("/api/authUsers/login", {
        email,
        password,
      });
      return { data };
    }
  } catch (error) {
    return { error: error };
  }
}
export async function loginWithGoogleUser(user,oneTap) {
  try {
    let userInfo;
    if (user) {
      if(!oneTap){     
      const res = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      );
      userInfo = res?.data;
    }else{
      userInfo = user;
    }
      const { data } = await axios.post(
        "/api/authUsers/googleLogin",
        userInfo
      );
      console.log("res2", data);
      return data;
    }
    return null;
  } catch (error) {
    return { error: error };
  }
}

export async function UsersendOTPTochangePassword(email) {
  try {
    if (email) {
      const { data } = await axios.put(
        "/api/authUsers/sendOTPToSendPassword",
        { email }
      );
      return data;
    }
  } catch (error) {
    return { error: error };
  }
}
export async function UserVerifyOTPTochangePassword(data1) {
  try {
    if (data1) {
      const { data } = await axios.put(
        "/api/authUsers/verifyOTPToChangePassword",
        data1
      );
      return data;
    }
  } catch (error) {
    return { error: error };
  }
}

export async function adminsendOTPTochangePassword(email) {
  console.log(email);
  try {
    if (email) {
      const { data } = await axios.put("/api/authAdmin/sendOTPToSendPassword", {
        email,
      });
      return data;
    }
  } catch (error) {
    return { error: error };
  }
}
export async function adminVerifyOTPTochangePassword(data1) {
  try {
    if (data1) {
      const { data } = await axios.put(
        "/api/authAdmin/verifyOTPToChangePassword",
        data1
      );
      return data;
    }
  } catch (error) {
    return { error: error };
  }
}
export async function reviewersendOTPTochangePassword(email) {
  try {
    if (email) {
      const { data } = await axios.put(
        "/api/authReviewers/sendOTPToSendPassword",
        { email }
      );
      return data;
    }
  } catch (error) {
    return { error: error };
  }
}
export async function reviewerVerifyOTPTochangePassword(data1) {
  try {
    if (data1) {
      const { data } = await axios.put(
        "/api/authReviewers/verifyOTPToChangePassword",
        data1
      );
      return data;
    }
  } catch (error) {
    return { error: error };
  }
}
export async function publishersendOTPTochangePassword(email) {
  try {
    if (email) {
      const { data } = await axios.put(
        "/api/authPublishers/sendOTPToSendPassword",
        { email }
      );
      return data;
    }
  } catch (error) {
    return { error: error };
  }
}
export async function publisherVerifyOTPTochangePassword(data1) {
  try {
    if (data1) {
      const { data } = await axios.put(
        "/api/authPublishers/verifyOTPToChangePassword",
        data1
      );
      return data;
    }
  } catch (error) {
    return { error: error };
  }
}
export async function loginReviewer({ email, password }) {
  try {
    if (email) {
      const { data } = await axios.post("/api/authReviewers/loginReviewer", {
        email,
        password,
      });
      return { data };
    }
  } catch (error) {
    return { error: error };
  }
}
export async function loginAdmin({ email, password }) {
  try {
    if (email) {
      const { data } = await axios.post("/api/authAdmin/loginAdmin", {
        email,
        password,
      });
      return { data };
    }
  } catch (error) {
    return { error: error };
  }
}
export async function loginPublisher({ email, password }) {
  try {
    if (email) {
      const { data } = await axios.post("/api/authPublisher/loginPublisher", {
        email,
        password,
      });
      return { data };
    }
  } catch (error) {
    return { error: error };
  }
}
