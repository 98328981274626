import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useForm } from "react-hook-form";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  //  loginUser, 
  loginWithGoogleUser } from "../../../../apiCalls/authApicalls";
import { Button, Col, Container, Row } from 'react-bootstrap';

import {
  GoogleLogin,
    useGoogleLogin, 
    // useGoogleOneTapLogin
   } from '@react-oauth/google';

   import { useGoogleOneTapLogin } from 'react-google-one-tap-login';

   
   const LoginContainer = () => {
     const navigate = useNavigate();
     // const {
       //   register,
       //   handleSubmit,
       //   formState: { errors },
       
       // } = useForm({
         //   mode: "onChange",
         // });
         
         useGoogleOneTapLogin({
             onError: error => console.log(error),
             onSuccess: response => googlelogin(response,true),
             googleAccountConfigs: {
               client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
             },
           });
         
         



//   const onSubmit = async (data) => {
//     // const { userName, first_name, second_name, password, email } = data;
    
//     const loginPromise = await loginUser(data)
//     if(loginPromise?.data?.status){
//     // console.log("res",loginPromise)
//       toast(`${loginPromise?.data?.data}`);
//       if(loginPromise?.data?.status === "Success"){
//         let { token } = loginPromise?.data;
//         localStorage.setItem('token', token);
//         // console.log("login success")
//         navigate("/");
//         window.location.reload();
//       }
//     }else{
//       toast("Server response error",loginPromise);
//     }
// }

  useEffect(() => {
		document.body.classList.add("account-page");
		return () => document.body.classList.remove("account-page");
		}, []);

    // useGoogleOneTapLogin({
    //   onSuccess: credentialResponse => {
    //     console.log(credentialResponse)
    //     googlelogin(credentialResponse)
    //   },
    //   onError: () => {
    //     console.log('Login Failed');
    //   },
    // });
  
  

    const google_btn_submit = useGoogleLogin({
      onSuccess: (codeResponse) =>googlelogin(codeResponse,false),
      onError: (error) => console.log('Login Failed:', error)
  });

  const googlelogin = async (data,oneTap) => {
    // const { userName, first_name, second_name, password, email } = data;
    const loginPromise = await loginWithGoogleUser(data,oneTap)
    console.log("loginPromise",loginPromise)
    if(loginPromise?.status){
    // console.log("res",loginPromise)
      toast(`${loginPromise?.data}`);
      if(loginPromise?.status === "Success"){
        let { token } = loginPromise;
        localStorage.setItem('token', token);
        // console.log("login success")
        navigate("/");
        window.location.reload();
      }
    }
}

	

  return (
    <>
    <Container className="p-2 p-sm-4 mt-auto">
      <Row className="justify-content-center">
        <Col md="7" lg="5" xl="5" xxl="4">
          <div className="nk-block">
            <div className="nk-block-head text-center mb-4 pb-2">
              <div className="nk-block-head-content">
                <h1 className="nk-block-title mb-1">Sign In</h1>
                <p className="small">
                  Sign in to your account to customize your pdf generation
                  settings and view your history.
                </p>
              </div>
            </div>
            {/* <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row gy-3">
              <Col lg="12">
                      <div className="form-group form-focus col-lg-12">
                        <label className="focus-label">Email</label>
                        <input type="email" className="form-control floating" 
                          placeholder="Enter email address"
                                                  {...register("email", {
                                                    required: "Email is required!",
                                                  })}/>
                        {errors.email && (
                          <span className="text-danger mt-2 d-inline-block">
                            {errors.email?.message}
                          </span>
                        )}
                      </div>
                      </Col>
                      <Col lg="12">                  <div className="form-group form-focus">
                        <label className="focus-label">Password</label>
                        <input
                          type="password"
                          className="form-control floating"
                          placeholder="Enter Password"
                          {...register("password", {
                            required: "Password is required!",
                          })}
                        />
                        {errors.password && (
                          <span className="text-danger mt-2 d-inline-block">
                            {errors.password?.message}
                          </span>
                        )}
                      </div>
                      </Col>
                      <Col lg='12'>
    
                      <div className="text-end">
                        <Link to="/forgot-password-author" className="forgot-link">
                          Forgot Password ?
                        </Link>
                      </div>
                      </Col>
                      <Col lg='12'>
    <div className="d-grid">
                      <button
                        className="btn btn-primary"
                        type="submit"
                      >
                        Login
                      </button>
                      </div>
                      </Col>
    
                      </div>

                    </form>
            <div className="text-center mt-3">
              <p className="small">
                Don’t have an account?{' '}
                <Link href="/create-account">Sign up</Link>
              </p>
            </div>
            <div className="my-3 text-center">
              <h6 className="overline-title overline-title-sep">
                <span>OR</span>
              </h6>
            </div> */}
            <Row className="g-2">
              <Col lg="12">
                <Button variant="outline-light w-100 google-btn" onClick={google_btn_submit}>
                  <img
                    src="/images/icons/google.png"
                    className="icon h-auto"
                    width={21}
                    height={20}
                    alt="google"
                  />
                  <span className="fw-medium">Continue with Google</span>
                </Button>
                {/* <GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse);
  }}
  onError={() => {
    console.log('Login Failed');
  }}
  useOneTap
/>; */}
              </Col>

            </Row>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default LoginContainer;
