import { jwtDecode } from "jwt-decode";
export async function decodeToken() {
  const token = localStorage.getItem("token");
  if (!token) return null;
  let decode = jwtDecode(token);
  return decode;
}

export const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};
