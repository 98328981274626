
import React from 'react'
import { BuilderContext } from '../../index'
import './template2.css'

    

function Template2({a}) {
  const education = a?.Education
  const skills = a?.KeySkills
  const profile= a?.Profile;
  // const contact = a?.Contact')
  const socials= a?.Socials
  const others = a?.Other
  const certifications = a?.Certifications
  const employment = a?.Employment
  const projects = a?.Projects


  return (
    <BuilderContext.Provider value={a}>
    <div className="template2">
      <div className="template2-inner">

    <div className="temp2-main">
      <header className="temp2-title">
        <h1>{profile?.name}</h1>
        <span className="temp2-subtitle">{profile?.profession}</span>
      </header>
      {/* Summary  */}
      {profile?.display && profile?.about && profile.about !=="" &&
      <section className="temp2-main-block concise temp2-header-container">
        <h2> Summary
        </h2>
 
        <section className="temp2-blocks">
          <div className="temp2-date">
            {/* <span>{item?.date?.from}</span><span>{item?.date?.to}</span> */}
          </div>
          <div className="temp2-decorator">
          </div>
          <div className="temp2-summary-details">
            <div>{profile?.about}</div>
          </div>
        </section>

      </section>
}
      {/* Education */}
      {education?.display &&
      <section className="temp2-main-block concise temp2-header-container">
        <h2>
          <i className="fa fa-graduation-cap"></i> Education
        </h2>
        {education?.items.map((item, index) => (
        <section className="temp2-blocks">
          <div className="temp2-date">
            <span>{item?.dateFrom}</span><span>{item?.dateTo}</span>
          </div>
          <div className="temp2-decorator">
          </div>
          <div className="temp2-details">
            <div className='temp2-header-containers'>
              <h3>{item?.degree}</h3>
              <span className="temp2-place">{item?.institution}</span>
              {item?.location && item?.location === "" &&
              <span className="temp2-location">{item?.location}</span>}
            </div>
            <div>{item?.details}</div>
          </div>
        </section>
        ))}
      </section>
}
      {/* Employment */}
{employment?.display &&
      <section className="temp2-main-block temp2-header-container">
        <h2>
          <i className="temp2-fa temp2-fa-suitcase"></i> Experiences
        </h2>
        {employment?.items.map((item, index) => (
        <section className="temp2-blocks"   key={index}>
          <div className="temp2-date">
            <span>{item.dateFrom}</span><span>{item.dateTo}</span>
          </div>
          <div className="temp2-decorator">
          </div>
          <div className="temp2-details">
            <div className='temp2-header-containers'>
              <h3>{item.position}</h3>
              <span className="temp2-place">{item.location}</span>
              {item?.location && item?.location === "" &&
              <span className="temp2-location">{item?.location}</span>
}
            </div>
        
              <ul>
             
              {item?.responsibilities?.map((item, index)=>(
 <li key={index}> {item} </li>
             ) )}
            
              </ul>
           
          </div>
        </section>
            ))}
      </section>
}
      {/* Projects  */}
      {projects?.display &&
      <section className="temp2-main-block temp2-header-container">
        <h2>
          <i className="temp2-fa fa-folder-open"></i> Projects
        </h2>
        {projects?.items.map((item, index) => (
        <section className="temp2-blocks"   key={index}>
          <div className="temp2-date">
            <span>{item.dateFrom}</span><span>{item.dateTo}</span>
          </div>
          <div className="temp2-decorator">
          </div>
          <div className="temp2-details">
            <div className='temp2-header-containers'>
              <h3>{item.title}</h3>
              <span className="temp2-place">{item.location}</span>
              {item?.location && item?.location === "" &&
              <span className="temp2-location">{item?.location}</span>
}
            </div>
        
              <ul>
             
              {item?.description?.map((item, index)=>(
 <li key={index}> {item} </li>
             ) )}
            
              </ul>
           
          </div>
        </section>
            ))}
      </section>
}
      {/* Others  */}
      {others?.items?.map((item, index) => (
<div>
        {item?.display &&
        <section className="temp2-main-block temp2-header-container">
          <h2>
            <i className="temp2-fa fa-folder-open"></i> {item?.name}
          </h2>
     
          <section className="temp2-blocks"   key={index}>
            <div className="temp2-date">
            {item.dateFrom && item.dateFrom && item.dateFrom !=="" && item.dateFrom !=="" &&
            <>
              <span>{item.dateFrom}</span><span>{item.dateTo}</span>
            </>
            }
            </div>
            <div className="temp2-decorator">
            </div>
            <div className="temp2-details">
              <div className='temp2-header-containers'>
              {item.position &&
                <h3>{item.position}</h3>
              }
           
                {item?.location && item?.location === "" &&
                <span className="temp2-location">{item?.location}</span>
  }
              </div>
          
                <ul>
               
                {item?.details?.map((item, index)=>(
   <li key={index}> {item} </li>
               ) )}
              
                </ul>
             
            </div>
          </section>
     
        </section>
  }
  </div>
      ))}
      
   
    </div>
    <aside className="temp2-sidebar">
    {profile?.display &&
      <div className="temp2-side-block temp2-contact">
        <h1>
          Contact Info
        </h1>
        <ul>
          {/* <li><i className="fa fa-globe"></i>{socials.items}</li> */}
          <li><i className="fa fa-envelope"></i>{profile?.email}</li>
          <li><i className="fa fa-phone"></i> {profile?.phoneNumber}</li>
          {socials?.items?.map((a,i)=>{
            if(a?.enabled)
              
            return(
            <li>{a.url}</li>           
)
return null;
})}
         
        </ul>
      </div>
}
{skills?.display &&
      <div className="temp2-side-block temp2-skills">
        <h1>
          Skills
        </h1>
        <ul>
        {skills?.text?.map((item, index) => (
          <li>{item}</li>
        ))}
        </ul>

      </div>
}
{certifications?.display &&
      <div className="temp2-side-block temp2-skills">
        <h1>
          Certifications
        </h1>
        <ul>
        {certifications?.items?.map((item, index) => (
          <li>{item}</li>
        ))}
        </ul>

      </div>
}
    </aside>
    </div>
</div>
</BuilderContext.Provider>
  )
}

export default Template2