import React from 'react';
import '../../../css/terminal.css'

export default function TerminalEditor({twitterPost,linkedinPost, userPrompt}) {

  return (

      <div>
      <div className="fakeMenu">
  <div className="fakeButtons fakeClose"></div>
  <div className="fakeButtons fakeMinimize"></div>
  <div className="fakeButtons fakeZoom"></div>
</div>
<div className="fakeScreen">
{!twitterPost && !linkedinPost &&<p className="line1">$ waiting For your Prompt<span className="cursor1">_</span></p>}
  <p className="line2">{userPrompt}<span className="cursor2">_</span></p>
  <p className="line3">{twitterPost || linkedinPost}<span className="cursor3">_</span></p>
  {/* <p className="line4">{'>'}<span className="cursor4">_</span></p> */}
</div>
      </div>
  );
}
