import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// User
// import UserRegister from "./client/components/users/register/useronboarding/onboarding.jsx";
// import UserForgotPassword from "./client/components/users/forgot-password/index.jsx";
import UserPlaygroundDashboard from "./client/components/users/dashboard/playground/index.jsx";
import Resume2Dashboard from "./client/components/users/dashboard/resume/index2.jsx";
import Resume1Dashboard from "./client/components/users/dashboard/resume/index3.jsx";
import TwitterCampaignsDashboard from "./client/components/users/dashboard/campaigns/twitter/index.jsx";
import LinkedinCampaignsDashboard from "./client/components/users/dashboard/campaigns/linkedin/index.jsx";
import CreateCampaign from "./client/components/users/dashboard/create/index.jsx";
import UserLogin from "./client/components/users/login/index.jsx";
import UserDashboard from "./client/components/users/dashboard/first-dashboard/index.jsx";
import CreateResumeDashboard from "./client/components/users/dashboard/resume/index.jsx";
import ResumeTemplatesDashboard from "./client/components/users/dashboard/resume/show-templates/index.jsx";
import UserProfileSetting from "./client/components/users/dashboard/profilesetting/index.jsx";
import UserChangePassword from "./client/components/users/dashboard/password/index.jsx";
import PricingPlansPage from "./client/components/users/dashboard/pricing/index.jsx";
import SubscriptionDashbord from "./client/components/users/dashboard/subscription/index.jsx";
import CommingSoon from "./client/components/users/dashboard/comming-soon/index.jsx";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/resume" element={<CreateResumeDashboard/>}/>
        {/* <Route path="/resume/:id" element={<CreateResumeDashboard/>}/> */}
        <Route exact path="/resume2" element={<Resume2Dashboard/>}/>
        <Route exact path="/resume1" element={<Resume1Dashboard/>}/>
        <Route exact path="/resume-templates" element={<ResumeTemplatesDashboard/>}/>
        <Route exact path="/" element={<UserDashboard />} />
        <Route exact path="/subscription" element={<SubscriptionDashbord />} />
       {/* <Route exact path="/create-campaign" element={<CreateCampaign />} />
         <Route
          exact
          path="/campaigns/linkedin"
          element={<LinkedinCampaignsDashboard />}
        />
        <Route exact path="/campaigns/twitter" element={<TwitterCampaignsDashboard />} />
       
        <Route path="/playground" element={<UserPlaygroundDashboard />} /> */}
        <Route exact path="/create-campaign" element={<CreateCampaign />} />
        <Route
          exact
          path="/campaigns/linkedin"
          element={<LinkedinCampaignsDashboard />}
        />
        <Route exact path="/campaigns/twitter" element={<TwitterCampaignsDashboard />} />
        {/* User */}
        <Route path="/playground" element={<UserPlaygroundDashboard />} />
        <Route path="/login" element={<UserLogin />} />
        {/* <Route path="/forgot-password" element={<UserForgotPassword />} /> */}
        <Route
          path="/profile-setting"
          element={<UserProfileSetting />}
        />
                <Route
          path="/pricing-plans"
          element={<PricingPlansPage />}
        />
        <Route
          path="/user-dashboard/user-change-password"
          element={<UserChangePassword />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
