import axios from "axios";

export const userCreateResume = async (record) => {
    console.log(record)

  try {
    const { data } = await axios.post("/api/resumes/create", record);
    return data;
  } catch (error) {
    return null;
  }
};

  export const userCreateCampaign = async (record) => {
    

    try {
      const { data } = await axios.post("/api/campaigns/create", record);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const generatePdf = async (html,template,name) => {
console.log("IS IT CALLING",html,template,name)
    try {
      // const { data } = await axios.post("/api/users/generatePdf", {html, template});
      const response = await axios.post('/api/users/generatePdf', {html, template},{ responseType: 'blob' });
      console.log("response from server,",response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
      return response.data;
    } catch (error) {
      return null;
    }
  };
  export const userDeleteCampaign = async (record) => {
    

    try {
      const { data } = await axios.post("/api/campaigns/delete", record);
      return data;
    } catch (error) {
      return null;
    }
  };
  export const paymentApi = async (record) => {
    try {
      const {data} = await axios.post("/api/payment/create-checkout-session/",record,        
      {
        headers: {
          Accept: "application/json",
        },
      });
      return data;
    } catch (error) {
      return null
    }
  };
  export const cancelSubscriptionApi = async (record) => {
    try {
      const {data} = await axios.post("/api/payment/billing-session/cancelSubscription",record,        
      {
        headers: {
          Accept: "application/json",
        },
      });
      return data;
    } catch (error) {
      return null
    }
  };
  export const updateSubscriptionApi = async (record) => {
    try {
      const {data} = await axios.post("/api/payment/billing-session/updateSubscription",record,        
      {
        headers: {
          Accept: "application/json",
        },
      });
      return data;
    } catch (error) {
      return null
    }
  };
  export const checkDiscount = async (id,email) => {

    try {
      const { data }  = await axios.post("/api/payment/checkDiscount", { id, email });
      return data
    } catch (error) {
      return null;
    }
  };



  export const AddEditor = async (data1) => {
    try {
      const { data } = await axios.post('/api/reviewers/addReviewer', data1);
      if(data.status === "Success"){
        let text = `Dear Reviewer, You can Register Using This Link By the same Email. http://jbrahma.io/register-Reviewer`;
        const res = await axios.post('/api/authAdmin/registerMail', { username: data1.name, userEmail: data1.email, text, subject : "Your Email Address has been added to our registered Reviewers list"})
        return res;
    }else{
      return {status:"error", data:data.data}
    }
    } catch (error) {
      return null;
    }
  };
  
  