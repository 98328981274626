import {  useState } from 'react'
import { Hide } from './Icons/Hide'
import Show from './Icons/Show'
import { Add } from './Icons/Add'
import { Up } from './Icons/Up'
import { Down } from './Icons/Down'
import { Remove } from './Icons/Remove'
const EmploymentItem = ({ data, index,savechanges, className }) => {

  const [isToggled, setIsToggled] = useState(true)
  let empData = data
  let responsibilities = data.responsibilities
  const handleChange = ( e) => {
    const targetName = e.target.name
    empData = {...empData, [targetName]:e.target.value}
    savechanges(index,empData)
  }

  return (
    <div className='flex pt-10px'>
      <div className='look-btn-emp'>
      {!isToggled ? (
        <Hide
        handleClick={() => {
            setIsToggled(true)
          }}
        />
      ) : (
        <Show
        handleClick={() => {
            setIsToggled(!isToggled)
          }}
          />
      )}
      </div>
      <div>
<div className='col-12'>
        <input
      className={className}
      placeholder='Postion Title - Company'
        isDisabled={isToggled}
        name='position'
        defaultValue={empData.position}
        onChange={(e) => handleChange(e)}
        />
       </div>
      {!isToggled && (
       <>
       <div className='row '>
        <div className='col-6 gy-2'>
          <label className="form-label">
          Date From 
            </label>
          <input
            className={className}
            placeholder='Date From - To'
            name='dateFrom'
            defaultValue={empData?.dateFrom}
            onChange={(e) => handleChange(e)}
          />
           </div>
           <div className='col-6 gy-2'>
          <label className="form-label">
          Date  To
            </label>
          <input
            className={className}
            placeholder='Date From - To'
            name='dateTo'
            defaultValue={empData?.dateTo}
            onChange={(e) => handleChange(e)}
          />
           </div>
           </div>
        
          <div className='col-12 gy-2'>
          <label className="form-label">
          Location
            </label>
          <input
            className={className}
            placeholder='Position Description'
            name='location'
            defaultValue={empData.location}
            onChange={(e) => handleChange(e)}
          />
          </div>
          <div className='col-12 gy-2 last-form-member'>
            <div className='flex justify-items pt-10px ml-20px '>
          <label className="form-label">
          Responsibilities
            </label>
            <div className='flex'>
        <Add color='#d1d5db'     
         handleClick={() =>{
          const a = [...responsibilities,"New Responsibilities"]
          responsibilities = a
          empData = {...empData, responsibilities:a}
          savechanges(index,empData)
        }} />

        <Remove color='#d1d5db' handleClick={() =>{
          const a = responsibilities.filter((a,i) => i !== responsibilities.length - 1)
          responsibilities = a;
          console.log(a)
          empData = {...empData, responsibilities:a}
          savechanges(index,empData)
        }} />
        </div>
      </div>
            {responsibilities.map((item,resIndex)=>(
              <div className='my-1 flex'>
                 <div className='mt--5 flex ml--5px'>
            <Up     color='#d1d5db'     
              handleClick={() =>{
                if(resIndex !== 0 ){
                const temp = responsibilities[resIndex - 1];
                responsibilities[resIndex - 1] = responsibilities[resIndex];
                responsibilities[resIndex] = temp;
                console.log(responsibilities)
                empData = {...empData, responsibilities}
                console.log(empData)
                savechanges(index,empData)
                }
        }}/>
             <Down  color='#d1d5db'     
          handleClick={() =>{
            if(resIndex !== (responsibilities.length-1) ){
            const temp = responsibilities[resIndex + 1];
            responsibilities[resIndex + 1] = responsibilities[resIndex];
            responsibilities[resIndex] = temp;
            console.log(responsibilities)
          empData = {...empData, responsibilities}
          savechanges(index,empData)
        }}}/>
</div>
              <input
                className={className}
                placeholder='Responsibilities'
                name='responsibilities'
                value={item}
                onChange={(e) => {
                  const nextCounters = responsibilities.map((c, i) => {
                    if (i === resIndex) {
                      // Increment the clicked counter
                      return e.target.value;
                    } else {
                      // The rest haven't changed
                      return c;
                    }
                  });
                  console.log(nextCounters)
                  responsibilities = nextCounters
                  empData ={...empData, responsibilities:nextCounters}
                  savechanges(index,empData)
                }}
              />
              <div className='mt--5'>
          <Remove color='#d1d5db' handleClick={() =>{
          const a = responsibilities.slice(0, resIndex).concat(responsibilities.slice(resIndex + 1));
          responsibilities = a;
          console.log(a)
          empData = {...empData, responsibilities:a}
          savechanges(index,empData)
        }} />
      
 
</div>   
</div>
            ))}
          </div>
        </>
    
      )}
      </div>
      </div>

  )
}

export default EmploymentItem
